import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "cpfInput", "nameInput", "notesInput", "saveBtn", "idNameInput", "idNumberInput",
                    "chapterDropdown", "chapterDropdownBtn", "chapterInput", "chapterList",
                    "statusDropdown", "statusDropdownBtn", "statusInput", "statusList",
                    "termDropdown", "termDropdownBtn", "termInput", "termList"]


  connect() {
    this.controllerName = `operations--products--tax-filings--files--save`
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier("operations--products--tax-filings--files--show").doViewHtml()
    this.getControllerByIdentifier("operations--products--tax-filings--files--index").cleanCheckBox()
  }

  saveDocument() {
    this.saveBtnTarget.disabled = true
    this.actionMode = `edit`
    this.stopRefreshing()

    this.send_data = { current_user: {}, file: {}, tracker: { manual: true } }

    this.send_data.file.id = this.current_file.id
    this.send_data.file.chapter = this.chapterInputTarget.dataset.chapter
    this.send_data.file.status = this.statusInputTarget.dataset.status
    this.send_data.file.term = this.termInputTarget.dataset.term
    this.send_data.file.name = this.nameInputTarget.value
    this.send_data.file.notes = this.notesInputTarget.value
    this.send_data.file.id_name = this.idNameInputTarget.value
    if (this.idNumberInputTarget.value) {
      this.send_data.file.id_number = this.getControllerByIdentifier("app--helpers--numbers").fromTaxIdToNumber(this.idNumberInputTarget.value)
      if (this.send_data.file.id_number == 11) {
        this.send_data.file.id_type = `individual`
      } else if (this.send_data.file.id_number == 14) {
        this.send_data.file.id_type = `legal`
      }
    }

    var dateToday = new Date()
    this.send_data.tracker.record_id = this.application.tax_filing.id
    this.send_data.tracker.record_type = `operation_products`
    this.send_data.tracker.obj_id = this.application.current_date.id
    this.send_data.tracker.obj_type = `operation_dates`
    this.send_data.tracker.action = `updated`
    this.send_data.tracker.observations = `Revisou o Arquivo ${this.send_data.file.name} para o Status ${this.statusInputTarget.innerText}`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = this.application.files_permission

    this.requestSave()
  }

  requestSave() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })

    var url = "/operations/products/tax_filings/files/update"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var file = response.data.cln
          controller.application.files.forEach((element, i) => {
            if (element.id == file.id) {
              controller.application.files.splice(controller.application.files.indexOf(element), 1, file)
            }
          })
          controller.cancelSave()
          controller.getControllerByIdentifier("operations--products--tax-filings--files--index").doDataTable()
        } else {
          controller.saveBtnTarget.disabled = false
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }

        controller.getControllerByIdentifier("operations--products--tax-filings--show--submenu").doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

      var checklist = `<div class="row my-2">
                        <div class="col-12 pr-2 pl-0">
                          <span class="f70">Checklist</span>
                        </div>
                        <div class="col-2">
                          <div class="custom-control custom-checkbox pl-1 d-flex align-items-center">
                            <input type="checkbox" class="custom-control-input" data-${this.controllerName}-target="checkboxShow" data-action="click->${this.controllerName}#editDocument">
                            <label class="custom-control-label pointer"></label>
                          </div>
                        </div>
                        <div class="col-10">
                          <span class="f70">Anotações</span>
                        </div>
                      </div>`

    if (this.application.permissions[this.application.files_permission].can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveDocument" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    // `<li data-status="processed" data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Processado</li>`
    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle">Editando Documento</h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-4 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Status</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="statusDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="statusDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="statusInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="statusList" data-app--helpers--search-target="searchList">
                                  <li data-status="not_revised" data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Revisado</li>
                                  <li data-status="approved" data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Aprovado</li>
                                  <li data-status="denied" data-action="click->${this.controllerName}#selectStatus click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Negado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-1 pr-1">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Capítulo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="chapterDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="chapterDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="chapterInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="chapterList" data-app--helpers--search-target="searchList">
                                  <li data-chapter="incomes" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Rendimentos</li>
                                  <li data-chapter="payments" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Pagamentos</li>
                                  <li data-chapter="assets_and_rights" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Bens e Direitos</li>
                                  <li data-chapter="booking" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Livro-Caixa</li>
                                  <li data-chapter="others" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outros</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Tempo</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="termDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="termDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="termInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="termList" data-app--helpers--search-target="searchList">
                                  <li data-term="short" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Rápido</li>
                                  <li data-term="medium" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Médio</li>
                                  <li data-term="long" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Longo</li>
                                  <li data-term="costly" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Custoso</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-12 px-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome Documento</label>
                            <input aria-describedby="nameFormHelp" class="form-control form-valid-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome Documento" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-12 px-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="notesForm">Anotações</label>
                            <textarea aria-describedby="notesFormHelp" class="form-control form-valid-control" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-5 pr-2 pl-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="idNumberForm">Identificador</label>
                            <input aria-describedby="idNumberFormHelp" class="form-control form-valid-control" id="idNumberForm" data-${this.controllerName}-target="idNumberInput" data-action="blur->${this.controllerName}#numberMask keypress->${this.controllerName}#numberMask blur->${this.controllerName}#numberValidate" placeholder="Identificador" type="tel" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-7 pr-0 pl-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="idNameForm">Nome</label>
                            <input aria-describedby="idNameFormHelp" class="form-control form-valid-control" id="idNameForm" data-${this.controllerName}-target="idNameInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("operations--products--tax-filings--files--dashboard").viewTarget.innerHTML = html)
    }).then(() => {

      controller.formCardTarget.style.height = ($(window).height() * 0.55) + "px"
      controller.refreshSaveBtn()
      
      controller.statusInputTarget.innerText = controller.current_file.status_pretty
      controller.statusInputTarget.dataset.status = controller.current_file.status
      controller.statusDropdownTarget.value = controller.current_file.status
      controller.setFieldsByStatus(controller.current_file.status)
      
      if (controller.current_file.chapter != undefined) {
        controller.chapterInputTarget.innerText = controller.current_file.chapter_pretty
        controller.chapterInputTarget.dataset.chapter = controller.current_file.chapter
        controller.chapterDropdownTarget.value = controller.current_file.chapter
      }
      
      if (controller.current_file.term != undefined) {
        controller.termInputTarget.innerText = controller.current_file.term_pretty
        controller.termInputTarget.dataset.term = controller.current_file.term
        controller.termDropdownTarget.value = controller.current_file.term
      }

      if (controller.current_file.name != undefined) { controller.nameInputTarget.value = controller.current_file.name }
      if (controller.current_file.id_name != undefined) { controller.idNameInputTarget.value = controller.current_file.id_name }
      if (controller.current_file.id_number != undefined) { controller.idNumberInputTarget.value = controller.current_file.id_number }
      if (controller.current_file.notes != undefined) { controller.notesInputTarget.value = controller.current_file.notes }
      
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      
    })
  }

  numberMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToDoubleId(ev)
  }

  numberValidate(ev) {
    var cleanNumber = this.getControllerByIdentifier("app--helpers--numbers").fromTaxIdToNumber(ev.target.value)

    if (cleanNumber.length <= 11) {
      this.cpfValidate(ev)
    } else if (cleanNumber.length <= 14) {
      this.cnpjValidate(ev)
    }
    
  }

  cpfValidate(ev) {
    var cleanCpf = this.getControllerByIdentifier("app--helpers--numbers").fromTaxIdToNumber(ev.target.value)
    if (ev.type == "blur" && cleanCpf.length == 11) {
      this.cpf_result = this.getControllerByIdentifier("app--helpers--tax-number").cpf_validate(ev.target.value)
      if (this.cpf_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cpf_result.message, 3000)
        this.idNumberInputTarget.classList.add("f-danger")
        this.idNumberInputTarget.classList.remove("f-normal")
      } else {
        this.idNumberInputTarget.classList.add("f-normal")
        this.idNumberInputTarget.classList.remove("f-danger")
      }
    } else {
      this.idNumberInputTarget.classList.add("f-normal")
      this.idNumberInputTarget.classList.remove("f-danger")
    }
  }

  cnpjValidate(ev) {
    var cleanCnpj = this.getControllerByIdentifier("app--helpers--numbers").fromTaxIdToNumber(ev.target.value)
    if (ev.type == "blur" && cleanCnpj.length == 14) {
      this.cnpj_result = this.getControllerByIdentifier("app--helpers--tax-number").cnpj_validate(ev.target.value)
      if (this.cnpj_result.invalid) {
        this.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", this.cnpj_result.message, 3000)
        this.idNumberInputTarget.classList.add("f-danger")
        this.idNumberInputTarget.classList.remove("f-normal")
      } else {
        this.idNumberInputTarget.classList.add("f-normal")
        this.idNumberInputTarget.classList.remove("f-danger")
      }
    } else {
      this.idNumberInputTarget.classList.add("f-normal")
      this.idNumberInputTarget.classList.remove("f-danger")
    }
  }

  selectStatus(ev) {
    this.setFieldsByStatus(ev.target.dataset.status)
  }

  setFieldsByStatus(status) {
    if (status == `denied`) {
      this.nameInputTarget.disabled = true
      this.idNameInputTarget.disabled = true
      this.idNumberInputTarget.disabled = true
      this.chapterDropdownBtnTarget.disabled = true
      this.termDropdownBtnTarget.disabled = true
    } else {
      this.nameInputTarget.disabled = false
      this.idNameInputTarget.disabled = false
      this.idNumberInputTarget.disabled = false
      this.chapterDropdownBtnTarget.disabled = false
      this.termDropdownBtnTarget.disabled = false
    }
  }

  setNotificationPhrases() {
    if (this.chapterInputTarget.innerText) {
      this.notification_chapter = `do Capítulo ${this.chapterInputTarget.innerText}`
    } else {
      this.notification_chapter = `sem Capítulo definido`
    }

    if (this.statusInputTarget.dataset.status == `approved`) {
      this.notification_status = `aprovou`
    } else if (this.statusInputTarget.dataset.status == `denied`) {
      this.notification_status = `negou`
    } else if (this.statusInputTarget.dataset.status == `processed`) {
      this.notification_status = `processou`
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.statusInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == `Não Revisado`) {
        len += 1
      }

      if (controller.statusInputTarget.innerText != `Negado` && controller.chapterInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText != `Negado` && controller.termInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText != `Negado` && controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.statusInputTarget.innerText == `Negado` && controller.notesInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
