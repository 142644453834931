import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "nameInput", "chartInput", "chartFilter", "saveBtn",
                    "medDropdown", "medDropdownBtn", "medInput", "medList", "providerInput", "providerFilter",
                    "channelDropdown", "channelDropdownBtn", "channelInput", "channelList", "dueDate", "amountInput",
                    "methodDropdown", "methodDropdownBtn", "methodInput", "methodList", "descriptionInput", "bankLineInput",
                    "bankLineDiv", "bankAccountDiv", "bankAccountInput", "pixKeyDiv", "pixKeyInput", "accrualDate",
                    "recurringCheckbox", "variableCheckbox", "deleteBtn", "cardDiv", "sameDayCheckbox", "chartAccountDiv",
                    "totalAmountInput", "splitInput", "amountSplitInput", "installmentInput", "transfersDiv", "channelTransferInput",
                    "prepaidDropdown", "prepaidDropdownBtn", "prepaidInput", "prepaidList", "prepaidDiv", "bodyCard",
                    "creditTakerDropdown", "creditTakerDropdownBtn", "creditTakerInput", "creditTakerList", "clientModal",
                    "subkindCard", "subkind", "loanTransferDiv", "borrowerInput", "clientDiv", "contractInput", "refundDiv", "refundModal",
                    "clientCol", "refundCol", "teamModalCol", "teamInput", "refundKindDropdown", "refundKindDropdownBtn", "refundKindInput", "refundKindList",
                    "pixKindDropdown", "pixKindDropdownBtn", "pixKindInput", "pixKindList", "assetsCard", "costInput", "projectInput",
                    "costModal", "projectModal", "medModal", "channelModal", "chartAccountModal", "providerModal", "teamModal",
                    "ticketCheckbox", "invoiceCard"]

  connect() {
    this.controllerName = `financials--books--payables--entities--save`
    // this.controllerName = `financials--books--payables--entities--save").doFormHtml
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
  }

  cancelSave() {
    this.stopRefreshing()
    this.getControllerByIdentifier(`financials--books--payables--entities--index`).doIndexListHtml()
  }

  savePayable() {
    this.saveBtnTarget.disabled = true
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal()
    this.stopRefreshing()
    this.send_data = { current_user: {}, payable: {}, ticket: {} }
    // this.send_data = { current_user: {}, payable: {}, ticket: {}, tracker: { log: true } }

    if (this.actionMode == `edit`) {
      this.send_data.payable.id = this.current_payable.id
    }

    if (this.actionMode == `new`) {
      this.send_data.payable.date_id = this.current_date.id
    } else if (this.actionMode == `edit` || this.actionMode == `copy`) {
      this.send_data.payable.date_id = this.current_payable.date_id
    }
    
    var chart = {}
    if (this.subkind == `transfers`) {
      var channelTransferId = this.channelTransferInputTarget.dataset.channelId
      var channel = {}
      this.application.channels.forEach(element => {
        if (element.id == channelTransferId) {
          channel = element
        }
      })
      this.application.chart_accounts.forEach(element => {
        if (element.id == channel.chart_id) {
          chart = element
        }
      })
    } else {
      var chartId = this.chartInputTarget.dataset.filter
      this.application.chart_accounts.forEach(element => {
        if (element.id == chartId) {
          chart = element
        }
      })
    }

    this.send_data.payable.channel_id = this.channelInputTarget.dataset.channelId
    this.send_data.payable.channel_name = this.channelInputTarget.value
    this.send_data.payable.provider_id = this.providerInputTarget.dataset.providerId
    this.send_data.payable.provider_name = this.providerInputTarget.value
    this.send_data.payable.due_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.payable.accrual_date = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.payable.amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.payable.total_amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.amountInputTarget.value)
    this.send_data.payable.chart_id = chart.id
    this.send_data.payable.chart_account = chart.chart_account
    this.send_data.payable.chart_name = chart.chart_name
    this.send_data.payable.chart_master_name = chart.master_name
    this.send_data.payable.chart_group = chart.group
    this.send_data.payable.kind = chart.kind
    this.send_data.payable.method = this.methodInputTarget.dataset.method
    this.send_data.payable.bank_line = this.bankLineInputTarget.value
    this.send_data.payable.bank_account = this.bankAccountInputTarget.value
    
    this.send_data.payable.installment = this.installmentInputTarget.value
    this.send_data.payable.variable = this.variableCheckboxTarget.checked
    this.send_data.payable.recurring = this.recurringCheckboxTarget.checked
    this.send_data.payable.subkind = this.subkind

    if (this.methodInputTarget.dataset.method == `pix`) {
      this.send_data.payable.pix_key = this.pixKeyInputTarget.value
      this.send_data.payable.pix_kind = this.pixKindInputTarget.dataset.kind
    }

    this.send_data.payable.cost_id = this.costInputTarget.dataset.costId
    this.send_data.payable.cost_name = this.costInputTarget.value
    this.send_data.payable.project_id = this.projectInputTarget.dataset.projectId
    this.send_data.payable.project_name = this.projectInputTarget.value
    

    if (this.subkind == `loan_expenses` || this.subkind == `loan_transfer`) {
      this.send_data.payable.borrower_id = Number(this.borrowerInputTarget.dataset.medId)
      if (this.teamInputTarget.value) {
        this.send_data.payable.record_id = this.teamInputTarget.dataset.teamId
        this.send_data.payable.record_type = `account_entities`
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - @${this.teamInputTarget.value}`
      } else {
        this.send_data.payable.description = this.descriptionInputTarget.value.trim()
      }
    } else if (this.subkind == `loan_prepaid`) {
      this.send_data.payable.borrower_id = Number(this.borrowerInputTarget.dataset.medId)
      this.send_data.payable.prepaid_kind = this.prepaidInputTarget.dataset.prepaid
      
      if (this.teamInputTarget.value) {
        this.send_data.payable.record_id = this.teamInputTarget.dataset.teamId
        this.send_data.payable.record_type = `account_entities`
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - @${this.teamInputTarget.value}`
      } else {
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - ${this.providerInputTarget.value}`
      }
    } else if (this.subkind == `prepaid_expenses`) {
      this.send_data.payable.prepaid_kind = this.prepaidInputTarget.dataset.prepaid

      if (this.teamInputTarget.value) {
        this.send_data.payable.record_id = this.teamInputTarget.dataset.teamId
        this.send_data.payable.record_type = `account_entities`
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - @${this.teamInputTarget.value}`
      } else {
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - ${this.providerInputTarget.value}`
      }
    } else if (this.subkind == `refund`) {
      this.send_data.payable.refund_kind = this.refund_kind
      
      if (this.refund_kind == `clients_refund`) {
        this.setClientSendData()
      } else if (this.refund_kind == `expenses_refund`) {
        this.send_data.payable.record_id = this.teamInputTarget.dataset.teamId
        this.send_data.payable.record_type = `account_entities`
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - Reembolso @${this.teamInputTarget.value}`
      }
    } else if (this.subkind == `miscellaneous_credits`) {
      this.send_data.payable.miscellaneous_kind = this.kind
      this.setClientSendData()
    } else {
      this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()}`

      if (this.teamInputTarget.value) {
        this.send_data.payable.record_id = this.teamInputTarget.dataset.teamId
        this.send_data.payable.record_type = `account_entities`
        this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - @${this.teamInputTarget.value}`
      }
    }

    if (this.teamInputTarget.value) {
      this.send_data.payable.account_ids = [this.teamInputTarget.dataset.teamId]
    }

    this.send_data.payable.med_id = Number(this.medInputTarget.dataset.medId)
    this.send_data.payable.med_name = this.medInputTarget.value

    this.send_data.ticket.board_id = this.current_date.id
    this.send_data.ticket.board_type = `financial_payables`
    this.send_data.ticket.board_subtype = ``
    this.send_data.ticket.board_path = window.location.pathname
    this.send_data.ticket.board_name = `Contas à Pagar - ${this.current_date.name}`
    this.send_data.ticket.date_id = this.current_date.id
    this.send_data.ticket.date_type = `financials_dates`
    this.send_data.ticket.owner_id = this.application.current_user.account_id
    this.send_data.ticket.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    // this.send_data.ticket.performer_id = this.application.current_user.account_id
    // this.send_data.ticket.performer_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.ticket.stage = `in_process`
    this.send_data.ticket.status = `in_time`
    this.send_data.ticket.sharing = `internal`
    this.send_data.ticket.term = `quick`
    this.send_data.ticket.priority = `maximum`
    // this.send_data.ticket.body = `Pgt. Ref. ${this.descriptionInputTarget.value.trim()} | ${this.controllerNumber.currencyOptionMask(this.amountInputTarget.value) } | ${chart.chart_name} | ${this.channelInputTarget.innerText}`
    this.send_data.ticket.body = `Pgt. Ref. ${this.descriptionInputTarget.value.trim()} | ${this.amountInputTarget.value} | ${chart.chart_name} | ${this.channelInputTarget.innerText}`
    this.send_data.ticket.due_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dueDateTarget.value)
    this.send_data.ticket.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.accrualDateTarget.value)
    this.send_data.ticket.flag = `blue`
    this.send_data.ticket.kind = `task`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_payable_entities`
    this.send_data.current_user.has_ticket = this.ticketCheckboxTarget.checked

    // this.send_data.tracker.record_id = this.current_payable.id
    // this.send_data.tracker.record_type = `financial_payables`
    // this.send_data.tracker.action = `paid`
    // this.send_data.tracker.observations = `Pagamento ${this.current_payable.description} Lançado, sendo Principal: ${this.current_payable.amount}`
    
    this.requestSave()
  }

  setClientSendData() {
    if (this.kind == `clients` || this.refund_kind == `clients_refund`) {
      var contract = {}
      this.application.contracts.forEach(element => {
        if (element.id == this.contractInputTarget.dataset.contractId) {
          contract = element
        }
      })

      this.send_data.payable.record_id = contract.client_id
      this.send_data.payable.record_type = `financial_client_entities`
      this.send_data.payable.contract_id = contract.id
      this.send_data.payable.client_id = contract.client_id
      this.send_data.payable.client_name = contract.client_name
      this.send_data.payable.client_number = this.controllerNumber.fromCnpjToNumber(contract.client_number_pretty)
      this.send_data.payable.description = `${this.descriptionInputTarget.value.trim()} - #${contract.client_name}`
      this.send_data.payable.client_ids = [contract.client_id]
    } else {
    }
  }

  requestSave() {
    if (this.actionMode == `new` || this.actionMode == `copy`) {
      var url = "/financials/books/payables/entities/create"
      var method = "POST"
    } else if (this.actionMode == `edit`) {
      var url = "/financials/books/payables/entities/update"
      var method = "PUT"
    }
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          controller.stopRefreshing()
          var payable = response.data.cln
          if (controller.actionMode == `new` || controller.actionMode == `copy`) {
            controller.application.payables[controller.application.payables.length] = payable
          } else if (controller.actionMode == `edit`) {
            controller.application.payables.forEach((element, i) => {
              if (element.id == payable.id) {
                controller.application.payables.splice(controller.application.payables.indexOf(element), 1, payable)
              }
            })
          }
          
          controller.getControllerByIdentifier(`financials--books--payables--entities--index`).doIndexListHtml()
        } else {
          controller.saveBtnTarget.disabled = false
        }

        controller.getControllerByIdentifier(`financials--books--payables--dashboard--submenu`).doBadgeCounts()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.saveBtnTarget.disabled = false
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  doFormGridHtml() {
    
    if (!this.current_date) {
      this.current_date = this.application.current_date
    }

    if (this.application.permissions.financial_payable_entities.can_create) {
      var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#savePayable" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row">
                      <div class="col-2" data-${this.controllerName}-target="subkindCard"></div>
                      <div class="col-10" data-${this.controllerName}-target="bodyCard"></div>
                    </div>
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--payables--entities--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.65) + "px"
      controller.doSubkindCards()
      controller.doFormHtml()
    })
  }

  doFormHtml() {
    this.stopRefreshing()

    var html = `<div class="row mt-2 d-flex align-items-center">
                  <div class="col-1 text-right px-0"></div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="accrualDateForm">Lançamento</label>
                        <input aria-describedby="accrualDateFormHelp" class="form-control form-valid-control" id="accrualDateForm" data-${this.controllerName}-target="accrualDate" placeholder="Lançamento" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 px-0">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-100 my-0 d-flex pointer">
                      <input type="checkbox" class="custom-control-input" id="sameDayCheck" data-${this.controllerName}-target="sameDayCheckbox" data-action="click->${this.controllerName}#sameDayCheck">
                      <label class="custom-control-label f-065" for="sameDayCheck">Mesmo Dia</label>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="dueDateForm">Vencimento</label>
                        <input aria-describedby="dueDateFormHelp" class="form-control form-valid-control" id="dueDateForm" data-${this.controllerName}-target="dueDate" placeholder="Vencimento" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="amountForm">Valor</label>
                        <input aria-describedby="amountFormHelp" class="form-control f-065" id="amountForm" data-${this.controllerName}-target="amountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#updateSplitAmountEv keyup->${this.controllerName}#updateSplitAmountEv" placeholder="Valor" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 d-flex">
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                      <input type="checkbox" class="custom-control-input" id="recurringCheck" data-${this.controllerName}-target="recurringCheckbox" data-kind="recurring">
                      <label class="custom-control-label f-065" for="recurringCheck">Recorrente</label>
                    </div>
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                      <input type="checkbox" class="custom-control-input" id="variableCheck" data-${this.controllerName}-target="variableCheckbox" data-kind="variable">
                      <label class="custom-control-label f-065" for="variableCheck">Variável</label>
                    </div>
                    <div class="custom-control custom-checkbox pr-0 pl-2 w-50 my-0 d-flex pointer">
                      <input type="checkbox" class="custom-control-input" id="ticketCheck" data-${this.controllerName}-target="ticketCheckbox">
                      <label class="custom-control-label f-065" for="ticketCheck">Gerar Ticket</label>
                    </div>
                  </div>
                </div>
                <div class="row mt-2 d-flex align-items-center">
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showMeds" data-channel-setter="true" data-element="medInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver as Meds</span>
                    </button>
                  </div>
                  <div class="col-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="medForm">MedCapital</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="medInput" type="text" placeholder="MedCapital" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showChannels" data-element="channelInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                    </button>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="channelForm">Canal Pagamento</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelInput" type="text" placeholder="Canal Pagamento" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Método</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="methodDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="methodDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="methodInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="methodList" data-app--helpers--search-target="searchList"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2 d-flex align-items-center">
                  <div class="col-1 text-right px-0"></div>
                  <div class="col-5">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="descriptionForm">Descrição</label>
                        <input aria-describedby="descriptionFormHelp" class="form-control" id="descriptionForm" data-${this.controllerName}-target="descriptionInput" placeholder="Descrição" type="text" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showProviders" data-element="providerInput" data-${this.controllerName}-target="providerModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Fornecedores</span>
                    </button>
                  </div>
                  <div class="col-5">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="nameForm">Fornecedor</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="providerInput" type="text" placeholder="Fornecedor" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2 d-flex align-items-center">
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showCosts" data-element="costInput" data-${this.controllerName}-target="costModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Centros de Custos</span>
                    </button>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="costForm">Centro de Custo</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="costInput" type="text" placeholder="Centro de Custo" required>
                      </div>
                    </div>
                  </div>

                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showProjects" data-element="projectInput" data-${this.controllerName}-target="projectModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Projetos</span>
                    </button>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="projectForm">Projeto</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="projectInput" type="text" placeholder="Projeto" required>
                      </div>
                    </div>
                  </div>

                  <div class="col-1 text-right px-0" data-${this.controllerName}-target="teamModalCol">
                    <button data-action="click->${this.controllerName}#showTeams" data-element="teamInput" data-${this.controllerName}-target="teamModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Parceiros</span>
                    </button>
                  </div>
                  <div class="col-3" data-${this.controllerName}-target="teamModalCol">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="medForm">Parceiro</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="teamInput" type="text" placeholder="Parceiro" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2 d-flex align-items-center d-none" data-${this.controllerName}-target="clientDiv refundDiv">
                  <div class="col-1"></div>
                  <div class="col-2 d-none" data-${this.controllerName}-target="clientCol">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Tomador do Crédito</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="creditTakerDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="creditTakerDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="creditTakerInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="creditTakerList" data-app--helpers--search-target="searchList">
                              <li data-kind="clients" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectCreditTaker" data-app--helpers--selector-target="select" class="li-selector dark f-065">Clientes</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 d-none" data-${this.controllerName}-target="refundCol">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Tipo de Reembolso</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="refundKindDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="refundKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="refundKindInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="refundKindList" data-app--helpers--search-target="searchList">
                              <li data-refund="clients_refund" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRefundKind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Clientes</li>
                              <li data-refund="expenses_refund" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectRefundKind" data-app--helpers--selector-target="select" class="li-selector dark f-065">Despesas</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-right px-0 d-none" data-${this.controllerName}-target="clientModal">
                    <button data-action="click->${this.controllerName}#showContracts" data-element="contractInput" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Clientes</span>
                    </button>
                  </div>
                  <div class="col-8 d-none" data-${this.controllerName}-target="clientModal">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="medForm">Cliente</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="contractInput" type="text" placeholder="Cliente" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2 d-flex align-items-center d-none" data-${this.controllerName}-target="prepaidDiv">
                  <div class="col-1"></div>
                  <div class="col-4">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Tipo de Antecipação</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="prepaidDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="prepaidDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="prepaidInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="prepaidList" data-app--helpers--search-target="searchList">
                              <li data-prepaid="prepaid_signatures" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectPrepaid" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação Assinaturas</li>
                              <li data-prepaid="prepaid_office" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectPrepaid" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação Escritório</li>
                              <li data-prepaid="prepaid_outsourcing" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectPrepaid" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação Serviços Terceiros</li>
                              <li data-prepaid="prepaid_people" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectPrepaid" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação Pessoal</li>
                              <li data-prepaid="prepaid_others" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectPrepaid" data-app--helpers--selector-target="select" class="li-selector dark f-065">Antecipação Outras</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2 d-flex align-items-center d-none" data-${this.controllerName}-target="loanTransferDiv">
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showMeds" data-element="borrowerInput" data-${this.controllerName}-target="medModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Tomadores de Mútuos</span>
                    </button>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="medForm">Tomador de Mútuo</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="borrowerInput" type="text" placeholder="Tomador de Mútuo" required>
                      </div>
                    </div>
                  </div>
                </div>

                
                <div class="row mt-2 d-flex align-items-center d-none" data-${this.controllerName}-target="chartAccountDiv">
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showChartAccounts" data-element="chartInput" data-${this.controllerName}-target="chartAccountModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver o Plano de Conta</span>
                    </button>
                  </div>
                  <div class="col-11">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="nameForm">Plano de Conta</label>
                        <input readonly="readonly" class="form-control f-075 pt-0" data-${this.controllerName}-target="chartInput" type="text" placeholder="Plano de Conta" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2 d-flex align-items-center d-none" data-${this.controllerName}-target="transfersDiv">
                  <div class="col-1 text-right px-0">
                    <button data-action="click->${this.controllerName}#showChannels" data-element="channelTransferInput" data-${this.controllerName}-target="channelModal" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                      <span class="material-icons md-sm md-dark">help_center</span>
                      <span class="mc-tooltiptext mc-tooltiptext-right">Clique para ver os Canais</span>
                    </button>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="channelForm">Canal de Recebimento</label>
                        <input readonly="readonly" class="form-control f-075 pt-1 pb-1" data-${this.controllerName}-target="channelTransferInput" type="text" placeholder="Canal de Recebimento" required>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-none" data-${this.controllerName}-target="cardDiv">
                  <div class="col-1 text-right px-0"></div>
                  <div class="col-3 pr-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="totalAmountForm">Valor Total</label>
                        <input aria-describedby="totalAmountFormHelp" class="form-control form-valid-control" id="totalAmountForm" data-${this.controllerName}-target="totalAmountInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Total" type="tel" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="splitForm"># Parcelas</label>
                        <input aria-describedby="splitFormHelp" class="form-control form-valid-control" id="splitForm" data-${this.controllerName}-target="installmentInput" data-action="blur->${this.controllerName}#splitAmountEv keyup->${this.controllerName}#splitAmountEv" placeholder="Parcelas" type="tel" required>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 pl-2">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="amountSplitForm">Valor Parcela</label>
                        <input disabled aria-describedby="amountSplitFormHelp" class="form-control form-valid-control" id="amountSplitForm" data-${this.controllerName}-target="amountSplitInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask" placeholder="Valor Parcela" type="tel" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-none" data-${this.controllerName}-target="bankLineDiv">
                  <div class="col-1 text-right px-0"></div>
                  <div class="col-11">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="bankLineForm">Linha Digitável Boleto (somente números, sem pontos)</label>
                        <input aria-describedby="bankLineFormHelp" class="form-control" id="bankLineForm" data-${this.controllerName}-target="bankLineInput" placeholder="Linha Digitável Boleto (somente números, sem pontos)" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-none"  data-${this.controllerName}-target="bankAccountDiv">
                  <div class="col-1 text-right px-0"></div>
                  <div class="col-11">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="bankAccountForm">Dados Bancários</label>
                        <input aria-describedby="bankAccountFormHelp" class="form-control" id="bankAccountForm" data-${this.controllerName}-target="bankAccountInput" placeholder="Dados Bancários" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-none" data-${this.controllerName}-target="pixKeyDiv">
                  <div class="col-1 text-right px-0"></div>
                  <div class="col-4">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label>Tipo PIX</label>
                        <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="pixKindDropdown">
                          <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="pixKindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="pixKindInput"></span></button>
                          <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                            <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                            <ul class="ul-select" data-${this.controllerName}-target="pixKindList" data-app--helpers--search-target="searchList">
                              <li data-kind="id_number" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">CPF/CNPJ</li>
                              <li data-kind="email" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">E-mail</li>
                              <li data-kind="phone" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Celular</li>
                              <li data-kind="random" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Chave Aleatória</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="form-group">
                      <div class="floating-label floating-label-sm">
                        <label for="pixKeyForm">Chave PIX</label>
                        <input aria-describedby="pixKeyFormHelp" class="form-control" id="pixKeyForm" data-${this.controllerName}-target="pixKeyInput" placeholder="Descrição" type="text" required>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.bodyCardTarget.innerHTML = html)
    }).then(() => {

      var date = new Date(this.current_date.year, this.current_date.month - 1, 1)
      var today = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickInsideDate($(this.accrualDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      if (today.getFullYear() > date.getFullYear()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickWithLimit($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      } else if (today.getFullYear() == date.getFullYear() && today.getMonth() > date.getMonth()) {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
      } else {
        controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(this.dueDateTarget), "monthly", date.getFullYear(), date.getMonth() + 1)
      }

      controller.accrualDateTarget.disabled = true
      controller.dueDateTarget.disabled = true
      controller.amountInputTarget.disabled = true
      controller.descriptionInputTarget.disabled = true
      controller.methodDropdownBtnTarget.disabled = true
      
      if (controller.actionMode == `edit` || controller.actionMode == `copy`) {

        if (controller.actionMode == `edit`) {
          controller.formTitleTarget.innerText = `Editando Pagamento`
        } else if (controller.actionMode == `copy`) {
          controller.formTitleTarget.innerHTML = `Novo Pagamento`
        }
        controller.accrualDateTarget.disabled = false
        controller.dueDateTarget.disabled = false
        controller.amountInputTarget.disabled = false
        controller.descriptionInputTarget.disabled = false
        controller.methodDropdownBtnTarget.disabled = false


        if (controller.application.current_med.id) {
          controller.current_med = controller.application.current_med
          // controller.medInputTarget.dataset.medId = controller.application.current_med.id
          // controller.medInputTarget.value = controller.application.current_med.name
        }

        controller.subkind = controller.current_payable.subkind
        controller.nameTarget(`${controller.subkind}Card`).classList.add(`bg-primary`)

        var description = controller.current_payable.description.replace(`Pgt. Ref. `, ``)

        controller.descriptionInputTarget.value = description.split("-")[0].trim()
        
        controller.providerInputTarget.dataset.providerId = controller.current_payable.provider_id
        controller.providerInputTarget.value = controller.current_payable.provider_name

        controller.dueDateTarget.value = controller.current_payable.due_date_pretty
        controller.accrualDateTarget.value = controller.current_payable.accrual_date_pretty

        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(controller.current_payable.amount)
        controller.chartInputTarget.dataset.filter = controller.current_payable.chart_id
        controller.chartInputTarget.dataset.text = controller.current_payable.chart_name
        controller.chartInputTarget.value = controller.current_payable.chart_name

        controller.methodDropdownTarget.value = controller.current_payable.method
        controller.methodInputTarget.innerText = controller.current_payable.method_pretty
        controller.methodInputTarget.dataset.method = controller.current_payable.method

        controller.medInputTarget.value = controller.current_payable.med_name
        controller.medInputTarget.dataset.medId = controller.current_payable.med_id

        controller.channelInputTarget.value = controller.current_payable.channel_name
        controller.channelInputTarget.dataset.channelId = controller.current_payable.channel_id

        controller.variableCheckboxTarget.checked = controller.current_payable.variable
        controller.recurringCheckboxTarget.checked = controller.current_payable.recurring

        controller.pixKeyInputTarget.value = controller.current_payable.pix_key
        controller.pixKindDropdownTarget.value = controller.current_payable.pix_kind
        controller.pixKindInputTarget.dataset.kind = controller.current_payable.pix_kind
        controller.pixKindInputTarget.innerText = controller.current_payable.pix_kind_pretty
        
        controller.bankLineInputTarget.value = controller.current_payable.bank_line

        if (controller.current_payable.method == `bank_slip`) {
          controller.bankLineDivTarget.classList.remove("d-none")
          controller.bankAccountDivTarget.classList.add("d-none")
          controller.pixKeyDivTarget.classList.add("d-none")
          controller.cardDivTarget.classList.add("d-none")
        } else if (controller.current_payable.method == `transfer`) {
          controller.bankLineDivTarget.classList.add("d-none")
          controller.bankAccountDivTarget.classList.remove("d-none")
          controller.pixKeyDivTarget.classList.add("d-none")
          controller.cardDivTarget.classList.add("d-none")
        } else if (controller.current_payable.method == `pix`) {
          controller.bankLineDivTarget.classList.add("d-none")
          controller.bankAccountDivTarget.classList.add("d-none")
          controller.pixKeyDivTarget.classList.remove("d-none")
          controller.cardDivTarget.classList.add("d-none")
        } else if (controller.current_payable.method == `credit_card`) {
          controller.bankLineDivTarget.classList.add("d-none")
          controller.bankAccountDivTarget.classList.add("d-none")
          controller.pixKeyDivTarget.classList.add("d-none")
          controller.cardDivTarget.classList.remove("d-none")

          controller.totalAmountInputTarget.disabled = true
          controller.totalAmountInputTarget.value = controller.amountInputTarget.value
          controller.amountSplitInputTarget.disabled = true
          controller.installmentInputTarget.value = controller.current_payable.installment
          controller.splitAmount(controller.current_payable.installment)
          // controller.amountSplitInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(0)
        }

        if (controller.current_payable.cost_id) {
          controller.costInputTarget.dataset.costId = controller.current_payable.cost_id
          controller.costInputTarget.value = controller.current_payable.cost_name
        }

        if (controller.current_payable.project_id) {
          controller.projectInputTarget.dataset.projectId = controller.current_payable.project_id
          controller.projectInputTarget.value = controller.current_payable.project_name
        }

        if (controller.current_payable.subkind == `miscellaneous_credits`) {
          controller.kind = `clients`

          controller.creditTakerDropdownTarget.value = controller.kind
          controller.creditTakerInputTarget.dataset.kind = controller.kind
          controller.creditTakerInputTarget.innerText = `Clientes`

          controller.clientModalTargets.forEach(element => {
            element.classList.remove(`d-none`)
          })

          if (controller.application.contracts.length > 0) {
            this.setContractName()
          } else {
            controller.getControllerByIdentifier(`financials--books--payables--dashboard--main`).getClients()
          }

          // controller.application.contracts.forEach(element => {
          //   if (element.id == controller.current_payable.contract_id) {
          //     contract = element
          //   }
          // })
          // controller.contractInputTarget.value = `${contract.client_name} - ${contract.client_number_pretty}`
          // controller.contractInputTarget.dataset.clientId = contract.client_id
          // controller.contractInputTarget.dataset.contractId = contract.id
        }

        if (controller.current_payable.record_type == `account_entities`) {
          controller.teamInputTarget.dataset.teamId = controller.current_payable.record_id
          if (description.split("@").length > 1) {
            controller.teamInputTarget.value = description.split("@")[1].trim()
          } else if (description.split("-").length > 1) {
            controller.teamInputTarget.value = description.split("-")[1].trim()
          }
        }

        if (controller.current_payable.subkind == `loan_expenses`) {
          var borrowerMed = {}

          controller.application.meds.forEach(element => {
            if (element.id == controller.current_payable.borrower_id) {
              borrowerMed = element
            }
          })

          controller.borrowerInputTarget.dataset.medId = controller.current_payable.borrower_id
          controller.borrowerInputTarget.value = borrowerMed.name
        }

        if (controller.current_payable.subkind == `transfers`) {
          var chart = {}
          var channel = {}

          var chartId = this.current_payable.chart_id
          this.application.chart_accounts.forEach(element => {
            if (element.id == chartId) {
              chart = element
            }
          })

          this.application.channels.forEach(element => {
            if (element.chart_id == chart.id) {
              channel = element
            }
          })

          controller.channelTransferInputTarget.value = channel.name
          controller.channelTransferInputTarget.dataset.channelId = channel.id
        }

        if (controller.current_payable.subkind == `invoice_card`) {
          controller.setInvoiceCardForm()
        }
        
        controller.setForm()

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerHTML = `Novo Pagamento | <span class="badge badge-secondary-danger">Favor Selecione o Tipo de Pagamento Primeiro</span>`
        // controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(this.randonNumber())
        controller.amountInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(0)

        if (controller.application.current_med.id) {
          controller.current_med = controller.application.current_med
          controller.medInputTarget.dataset.medId = controller.application.current_med.id
          controller.medInputTarget.value = controller.application.current_med.name
        }
      }

      controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  setInvoiceCardForm() {
    this.medModalTarget.disabled = true
    this.channelModalTarget.disabled = true
    this.providerModalTarget.disabled = true
    this.costModalTarget.disabled = true
    this.projectModalTarget.disabled = true
    this.teamModalTarget.disabled = true
    this.clientModalTarget.disabled = true
    this.chartAccountModalTarget.disabled = true
    this.descriptionInputTarget.disabled = true
    this.amountInputTarget.disabled = true
  }

  setContractName() {
    var contract = {}
    this.application.contracts.forEach(element => {
      if (element.id == this.current_payable.contract_id) {
        contract = element
      }
    })

    this.contractInputTarget.value = `${contract.client_name} - ${contract.client_number_pretty} - ${contract.name} - ${contract.started_at_pretty}`
    this.contractInputTarget.dataset.clientId = contract.client_id
    this.contractInputTarget.dataset.contractId = contract.id
  }

  sameDayCheck(ev) {
    var check = ev.currentTarget.checked

    if (check) {
      this.dueDateTarget.value = this.accrualDateTarget.value
    } else {
      this.dueDateTarget.value = ``
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  hideList(ev) {
    // this.getControllerByIdentifier("app--helpers--input").hideList(ev)
  }

  chartFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectChart(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterChart(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }
  
  listProviders() {
    var html = ``

    this.application.providers.forEach(element => {
      html += `<li data-action="click->${this.controllerName}#selectProvider" data-${this.controllerName}-target="providerFilterItem" data-text="${element.name}" data-filter="${element.id}" class="li-selector dark f-065">${element.name}</li>`
    })

    this.providerFilterTarget.innerHTML = html
  }

  selectMethod(ev) {
    var method = ev.currentTarget.dataset.method

    this.setBankData(method)
  }

  selectCreditTaker(ev) {
    this.cleanForm()
    this.kind = ev.currentTarget.dataset.kind

    this.clientModalTargets.forEach(element => {
      element.classList.remove(`d-none`)
    })
    // this.teamModalColTargets.forEach(element => {
    //   element.classList.add(`d-none`)
    // })

    this.application.chart_accounts.forEach(element => {
      if (element.name == ev.currentTarget.innerText && element.master_name == this.subkind) {
        this.chartInputTarget.dataset.filter = element.id
        this.chartInputTarget.dataset.text = element.chart_name
        this.chartInputTarget.value = element.chart_name
      }
    })

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectRefundKind(ev) {
    this.cleanForm()
    this.refund_kind = ev.currentTarget.dataset.refund

    this.teamModalColTargets.forEach(element => {
      element.classList.remove(`d-none`)
    })

    if (this.refund_kind == `clients_refund`) {
      this.clientModalTargets.forEach(element => {
        element.classList.remove(`d-none`)
      })

      this.application.chart_accounts.forEach(element => {
        if (element.name == ev.currentTarget.innerText && element.master == `asset`) {
          this.chartInputTarget.dataset.filter = element.id
          this.chartInputTarget.dataset.text = element.chart_name
          this.chartInputTarget.value = element.chart_name
        }
      })
    } else if (this.refund_kind == `expenses_refund`) {
      this.clientModalTargets.forEach(element => {
        element.classList.add(`d-none`)
      })

      this.chartInputTarget.dataset.filter = ``
      this.chartInputTarget.dataset.text = ``
      this.chartInputTarget.value = ``
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  selectPrepaid(ev) {
    // this.cleanForm()
  }

  setBankData(method) {
    if (method == `bank_slip`) {
      this.bankLineDivTarget.classList.remove("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    } else if (method == `transfer`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.remove("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    } else if (method == `pix`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.remove("d-none")
      this.cardDivTarget.classList.add("d-none")
    } else if (method == `credit_card`) {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.remove("d-none")

      this.totalAmountInputTarget.disabled = true
      this.totalAmountInputTarget.value = this.amountInputTarget.value
      this.amountSplitInputTarget.disabled = true
      this.installmentInputTarget.value = 1
      this.amountSplitInputTarget.value = this.amountInputTarget.value

      this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    } else {
      this.bankLineDivTarget.classList.add("d-none")
      this.bankAccountDivTarget.classList.add("d-none")
      this.pixKeyDivTarget.classList.add("d-none")
      this.cardDivTarget.classList.add("d-none")
    }
  }

  updateSplitAmountEv(ev) {
    var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(ev.currentTarget.value)

    // this.totalAmountInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(amount)
    this.totalAmountInputTarget.value = ev.currentTarget.value
    this.installmentInputTarget.value = 1
    this.amountSplitInputTarget.value = ev.currentTarget.value
  }

  splitAmountEv(ev) {
    var split = ev.currentTarget.value

    this.splitAmount(split)
  }

  splitAmount(split) {
    if (split) {
      var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountInputTarget.value) / split
    } else {
      var amount = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.totalAmountInputTarget.value)
    }

    this.amountSplitInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyOptionMask(amount)
  }

  providerFilter(ev) {
    this.getControllerByIdentifier("app--helpers--input").filterList(ev, 2)
  }

  selectProvider(ev) {
    this.getControllerByIdentifier("app--helpers--input").selectItem(ev)
  }

  enterProvider(ev) {
    if (ev.type == "keyup" && ev.key == "Enter") {
      this.getControllerByIdentifier("app--helpers--input").enterItem(ev)
    }
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  doSubkindCards() {
    var html = `<h6 class="card-title py-1 f-card90 text-center mt-3"><strong>Tipo de Pagamento</strong></h6>
                <hr class="mt-0">
                <div class="card pointer mt-3" data-${this.controllerName}-target="transfersCard subkind" data-subkind="transfers" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Transferências na Med</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="expensesCard subkind" data-subkind="expenses" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Pgt Despesas</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="taxesCard subkind" data-subkind="taxes" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Impostos</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="assetsCard subkind" data-subkind="assets" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Compra Ativos</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="prepaid_expensesCard subkind" data-subkind="prepaid_expenses" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Despesas Antecipadas</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="miscellaneous_creditsCard subkind" data-subkind="miscellaneous_credits" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Créditos Diversos</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="refundCard subkind" data-subkind="refund" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Reembolsos Time</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="invoice_cardCard subkind" data-subkind="invoice_card" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Fatura de Cartão</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="loan_transferCard subkind" data-subkind="loan_transfer" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Mútuo - Transferência</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="loan_expensesCard subkind" data-subkind="loan_expenses" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Mútuo - Pgt Despesas</strong></h6>
                  </div>
                </div>
                <div class="card pointer mt-3" data-${this.controllerName}-target="loan_prepaidCard subkind" data-subkind="loan_prepaid" data-action="click->${this.controllerName}#toggleSubkindEv">
                  <div class="card-header py-0 f-065 text-center">
                    <h6 class="card-title py-1 f-card110"><strong>Mútuo - Despesa Antecipada</strong></h6>
                  </div>
                </div>`


    this.subkindCardTarget.innerHTML = html
  }

  toggleSubkindEv(ev) {
    var currentCard = ev.currentTarget

    this.toggleSubkind(currentCard)
  }

  toggleSubkind(currentCard) {
    this.subkind = currentCard.dataset.subkind

    this.subkindTargets.forEach(card => {
      card.classList.remove(`bg-primary`)
    })

    currentCard.classList.add(`bg-primary`)

    this.refreshFormGridHtml()
  }

  refreshFormGridHtml() {
    var controller = this
    new Promise(function (resolve) {
      resolve(controller.doFormHtml())
    }).then(() => {
      controller.accrualDateTarget.disabled = false
      controller.dueDateTarget.disabled = false
      controller.amountInputTarget.disabled = false
      controller.descriptionInputTarget.disabled = false
      controller.methodDropdownBtnTarget.disabled = false

      if (controller.actionMode == `new` || controller.actionMode == `copy`) {
        controller.formTitleTarget.innerText = `Novo Pagamento`
      } else if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Pagamento`
      }

      controller.setForm()
    })
  }

  setForm() {
    if (this.subkind == `loan_transfer` || this.subkind == `loan_expenses`) {
      this.chartAccountDivTarget.classList.remove(`d-none`)
      this.loanTransferDivTarget.classList.remove(`d-none`)
      this.transfersDivTarget.classList.add(`d-none`)
      this.prepaidDivTarget.classList.add(`d-none`)
      this.clientDivTarget.classList.add(`d-none`)
      this.refundDivTarget.classList.add(`d-none`)
    } else if (this.subkind == `loan_prepaid`) {
      this.chartAccountDivTarget.classList.remove(`d-none`)
      this.loanTransferDivTarget.classList.remove(`d-none`)
      this.transfersDivTarget.classList.add(`d-none`)
      this.prepaidDivTarget.classList.remove(`d-none`)
      this.clientDivTarget.classList.add(`d-none`)
      this.refundDivTarget.classList.add(`d-none`)
    } else if (this.subkind == `transfers`) {
      this.descriptionInputTarget.value = `Transferência entre Contas`
      this.chartAccountDivTarget.classList.add(`d-none`)
      this.loanTransferDivTarget.classList.add(`d-none`)
      this.transfersDivTarget.classList.remove(`d-none`)
      this.prepaidDivTarget.classList.add(`d-none`)
      this.clientDivTarget.classList.add(`d-none`)
      this.refundDivTarget.classList.add(`d-none`)
    } else if (this.subkind == `prepaid_expenses`) {
      this.chartAccountDivTarget.classList.remove(`d-none`)
      this.loanTransferDivTarget.classList.add(`d-none`)
      this.transfersDivTarget.classList.add(`d-none`)
      this.prepaidDivTarget.classList.remove(`d-none`)
      this.clientDivTarget.classList.add(`d-none`)
      this.refundDivTarget.classList.add(`d-none`)
    } else if (this.subkind == `miscellaneous_credits`) {
      this.chartAccountDivTarget.classList.remove(`d-none`)
      this.loanTransferDivTarget.classList.add(`d-none`)
      this.transfersDivTarget.classList.add(`d-none`)
      this.prepaidDivTarget.classList.add(`d-none`)
      this.clientDivTarget.classList.remove(`d-none`)
      this.clientColTarget.classList.remove(`d-none`)
      this.refundDivTarget.classList.remove(`d-none`)
      this.refundColTarget.classList.add(`d-none`)
    } else if (this.subkind == `refund`) {
      this.chartAccountDivTarget.classList.remove(`d-none`)
      this.loanTransferDivTarget.classList.add(`d-none`)
      this.transfersDivTarget.classList.add(`d-none`)
      this.prepaidDivTarget.classList.add(`d-none`)
      this.clientDivTarget.classList.remove(`d-none`)
      this.clientColTarget.classList.add(`d-none`)
      this.refundDivTarget.classList.remove(`d-none`)
      this.refundColTarget.classList.remove(`d-none`)
    } else {
      this.chartAccountDivTarget.classList.remove(`d-none`)
      this.loanTransferDivTarget.classList.add(`d-none`)
      this.transfersDivTarget.classList.add(`d-none`)
      this.prepaidDivTarget.classList.add(`d-none`)
      this.clientDivTarget.classList.add(`d-none`)
      this.refundDivTarget.classList.add(`d-none`)

      this.teamModalColTargets.forEach(element => {
        element.classList.remove(`d-none`)
      })
    }

    if (this.subkind == `invoice_card`) {
      this.setInvoiceCardForm()
    }

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  setMethodList(channel) {
    var methodList = ``
    this.methodDropdownBtnTarget.disabled = false

    if (this.subkind == `transfers`) {
      methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`

      this.methodDropdownTarget.value = `transfer`
      this.methodInputTarget.innerText = `Transferência`
      this.methodInputTarget.dataset.method = `transfer`
      this.setBankData(`transfer`)
      this.bankAccountInputTarget.value = channel.name
    } else {
      if (channel.kind == `bank_account`) {
        methodList += `<li data-method="bank_slip" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Boleto Bancário</li>`
        methodList += `<li data-method="transfer" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Transferência</li>`
        methodList += `<li data-method="pix" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">PIX</li>`
        methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

        this.methodDropdownTarget.value = ``
        this.methodInputTarget.innerText = ``
        this.methodInputTarget.dataset.method = ``
      } else if (channel.kind == `credit_card`) {
        methodList += `<li data-method="credit_card" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Cartão de Crédito</li>`

        this.methodDropdownTarget.value = channel.kind
        this.methodInputTarget.innerText = `Cartão de Crédito`
        this.methodInputTarget.dataset.method = channel.kind
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      } else if (channel.kind == `cash`) {
        methodList += `<li data-method="cash" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Dinheiro</li>`

        this.methodDropdownTarget.value = channel.kind
        this.methodInputTarget.innerText = `Dinheiro`
        this.methodInputTarget.dataset.method = channel.kind
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      } else if (channel.kind == `payment`) {
        methodList += `<li data-method="bank_debt" data-action="click->app--helpers--selector#select click->${this.controllerName}#selectMethod" data-app--helpers--selector-target="select" class="li-selector dark f-065">Débito em Conta</li>`

        this.methodDropdownTarget.value = `bank_debt`
        this.methodInputTarget.innerText = `Débito em Conta`
        this.methodInputTarget.dataset.method = `bank_debt`
        this.methodDropdownBtnTarget.disabled = true
        this.setBankData(channel.kind)
      }
    }

    this.methodListTarget.innerHTML = methodList
  }

  setMainChannel(med) {
    var mainChannel = {}
    this.application.channels.forEach(channel => {
      if (channel.med_id == med.id && channel.subkind == `main`) {
        mainChannel = channel
      }
    })

    this.channelInputTarget.value = mainChannel.name
    this.channelInputTarget.dataset.channelId = mainChannel.id
    this.setMethodList(mainChannel)
  }

  cleanForm() {
    this.prepaidInputTarget.value = ``
    this.prepaidInputTarget.dataset.prepaid = ``
    this.contractInputTarget.value = ``
    this.contractInputTarget.dataset.contractId = ``
    this.teamInputTarget.value = ``
    this.teamInputTarget.dataset.accountId = ``
    this.borrowerInputTarget.value = ``
    this.borrowerInputTarget.dataset.medId = ``
    this.channelTransferInputTarget.value = ``
    this.channelTransferInputTarget.dataset.channelId = ``

    this.getControllerByIdentifier("app--helpers--forms").floatingLabel()
  }

  showMeds(ev) {
    var element = ev.currentTarget.dataset.element
    var channelSetter = ev.currentTarget.dataset.channelSetter

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--meds--modal" data-financials--books--dashboards--meds--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--meds--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).channelSetter = channelSetter
      controller.getControllerByIdentifier(`financials--books--dashboards--meds--modal`).open()
    })
  }

  showChannels(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--channels--modal" data-financials--books--dashboards--channels--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--channels--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).channel_kind = []
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).feature = `financial_payable_channels`
      controller.getControllerByIdentifier(`financials--books--dashboards--channels--modal`).open()
    })
  }

  showCosts(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--costs--modal" data-financials--books--dashboards--costs--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--costs--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).kind = `cost_center`
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).feature = `financial_payable_costs`
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).open()
    })
  }

  showProjects(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="financials--books--dashboards--costs--modal" data-financials--books--dashboards--costs--modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-financials--books--dashboards--costs--modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).controllerForm = controller
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).kind = `project`
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).feature = `financial_payable_costs`
      controller.getControllerByIdentifier(`financials--books--dashboards--costs--modal`).open()
    })
  }

  showProviders() {
    var modal = `financials--books--dashboards--providers--modal`
    
    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).feature = `financial_payable_providers`
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showChartAccounts(ev) {
    var element = ev.currentTarget.dataset.element
    var modalName = `financials--books--dashboards--chart-accounts--modal`

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modalName).controllerForm = controller
      controller.getControllerByIdentifier(modalName).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modalName).hasFilter = true
      controller.getControllerByIdentifier(modalName).permission = `financial_payable_chart_accounts`
      controller.getControllerByIdentifier(modalName).open()
    })
  }

  showContracts(ev) {
    var element = ev.currentTarget.dataset.element
    var modal = `financials--books--clients--contracts--list-modal`

    var html = `<div class="modal fade" data-controller="${modal}" data-${modal}-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modal}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(modal).controllerForm = controller
      controller.getControllerByIdentifier(modal).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(modal).permission = `financial_payable_clients`
      controller.getControllerByIdentifier(modal).recurring = false
      controller.getControllerByIdentifier(modal).open()
    })
  }

  showTeams(ev) {
    var element = ev.currentTarget.dataset.element

    var html = `<div class="modal fade" data-controller="users--accounts--entities--list-modal" data-users--accounts--entities--list-modal-target="modal" id="connectionModal" tabindex="-1" role="dialog" aria-labelledby="connectionModalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-slg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-users--accounts--entities--list-modal-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).controllerForm = controller
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).inputElement = this.nameTarget(element)
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).kind = `team`
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).permission = `financial_payable_entities`
      controller.getControllerByIdentifier(`users--accounts--entities--list-modal`).open()
    })
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.accrualDateTarget.value == ``) {
        len += 1
      }

      if (controller.dueDateTarget.value == ``) {
        len += 1
      }

      if (controller.amountInputTarget.value == `R$ 0,00` || controller.amountInputTarget.value == ``) {
        len += 1
      }

      if (controller.descriptionInputTarget.value == ``) {
        len += 1
      }

      if (controller.subkind != `transfers`) {
        if (controller.chartInputTarget.value == ``) {
          len += 1
        }
      }

      if (controller.medInputTarget.value == ``) {
        len += 1
      }

      if (controller.providerInputTarget.value == ``) {
        len += 1
      }

      if (controller.channelInputTarget.value == ``) {
        len += 1
      }

      if (controller.methodInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.methodInputTarget.innerText == `PIX` && controller.pixKindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.subkind == `loan_expenses` || controller.subkind == `loan_transfer` || controller.subkind == `loan_prepaid`) {
        if (controller.borrowerInputTarget.value == ``) {
          len += 1
        }
      }

      if (controller.subkind == `prepaid_expenses`) {
        if (controller.prepaidInputTarget.innerText == ``) {
          len += 1
        }
      }

      if (controller.subkind == `miscellaneous_credits`) {
        if (controller.contractInputTarget.value == `` || controller.contractInputTarget.value == `undefined - undefined`) {
          len += 1
        }
      }

      if (controller.subkind == `refund`) {
        if (controller.refundKindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.refund_kind == `clients_refund`) {
          if (controller.contractInputTarget.value == ``) {
            len += 1
          }
        } else if (controller.refund_kind == `expenses_refund`) {
          if (controller.teamInputTarget.value == ``) {
            len += 1
          }
        }
      }


      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  checkChartAccount(chartKind) {
    if (this.subkind == `expenses` || this.subkind == `loan_expenses` || this.subkind == `loan_prepaid` || this.subkind == `prepaid_expenses` || this.refund_kind == `expenses_refund`) {
      var chartArray = [
                        "infrastructure_technology_expenses",
                        "production_technology_expenses",
                        "staff_technology",
                        "accounting_expenses",
                        "staff_accounting",
                        "investment_expenses",
                        "staff_investment",
                        "protection_expenses",
                        "staff_protection",
                        "legal_expenses",
                        "staff_legal",
                        "customer_success_expenses",
                        "staff_customer_success",
                        "biling_expenses",
                        "staff_biling",
                        "prospecting_expenses",
                        "expenses_with_sales",
                        "offline_expenses",
                        "online_expenses",
                        "people_sales",
                        "people_marketing",
                        "location_expenses",
                        "utilities_expenses",
                        "office_expenses",
                        "human_capital_expenses",
                        "performance_expenses",
                        "staff_performance",
                        "staff_administrative",
                        "staff_board",
                        "bank_expenses",
                        "outsourcing_expenses",
                        "taxes_and_fees_expenses",
                        "new_business_expenses",
                        "staff_general",
                        "benefits_expenses",
                        "maintenance_expenses",
                        "depreciation_expenses",
                        "fines_and_interests_expenses",
                        "financial_taxes_expenses",
                        "exchange_variation_expenses",
                        "losses_realization",
                        "master_name_result",
                      ]
    } else if (this.subkind == `loan_transfer`) {
      var chartArray = [
                         "mutual_receivable",
                       ]
    } else if (this.subkind == `transfers`) {
      var chartArray = [
                         "availabilities",
                       ]
    } else if (this.subkind == `miscellaneous_credits`) {
      var chartArray = [
                         "miscellaneous_credits",
                       ]
    } else if (this.refund_kind == `clients_refund`) {
      var chartArray = [
                         "partners_refund_payables",
                       ]
    } else if (this.subkind == `taxes`) {
      var chartArray = [
                         // "federal_taxes",
                         // "city_taxes",
                         "short_term_taxes_payables",
                       ]
    } else if (this.subkind == `assets`) {
      var chartArray = [
                         "fixed_assets",
                       ]
    } else {
      var chartArray = [
                        "prepaid_expenses_and_taxes",
                        "receivables",
                        "tangible",
                        "intagible",
                        "technology_staff_expenses",
                        "operation_staff_expenses",
                        "technology_expenses",
                        "services_expenses",
                        "commercial_staff_expenses",
                        "sales_expenses",
                        "marketing_expenses",
                        "administrative_staff_expenses",
                        "administrative_expenses",
                        "general_staff_expenses",
                        "general_expenses",
                        "grant_depreciation_expenses",
                        "financial_expenses",
                        "grant_losses_realization",
                        "taxes_and_contributions"
                      ]
    }
    


    return chartArray.includes(chartKind)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // })
  // <input class="form-control f-075 pt-0" data-${this.controllerName}-target="chartInput" data-filter-mode="simple" data-action="focus->${this.controllerName}#chartFilter keyup->${this.controllerName}#chartFilter blur->${this.controllerName}#hideList keyup->${this.controllerName}#enterChart" type="text" placeholder="Plano de Conta" required>

}
