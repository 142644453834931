import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["mainCard", "viewTitle", "saveBtn", "sourceDropdown", "sourceDropdownBtn", "sourceInput", "sourceList", "nameInput",
                    "startedAtInput", "stageDropdown", "stageDropdownBtn", "stageInput", "stageList", "notesInput", "formCardBody",
                    "kindDropdown", "kindDropdownBtn", "kindInput", "kindList",
                    "campaignDropdown", "campaignDropdownBtn", "campaignInput", "campaignList",]

  connect() {
    this.controllerName = `commercial--sales--opportunities--entities--save`
  }

  doFormHtml() {

    var saveBtnHtml = `<button disabled type="button" class="btn btn-primary btn-sm btn-table f-065" data-action="click->${this.controllerName}#saveOpportunity" data-${this.controllerName}-target="saveBtn">Criar Oportunidade</button>`

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4" style="padding:1rem;font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                    <div class="row my-2">
                      <div class="col-8 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="nameForm">Nome</label>
                            <input aria-describedby="nameFormHelp" class="form-control" id="nameForm" data-${this.controllerName}-target="nameInput" placeholder="Nome" type="text" required>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 pl-2 pr-0">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label for="startedAtForm">Data Início</label>
                            <input aria-describedby="startedAtFormHelp" class="form-control" id="startedAtForm" data-${this.controllerName}-target="startedAtInput" placeholder="Data Início" type="text" required>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row my-2">
                      <div class="col-4 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Fonte</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="sourceDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="sourceDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="sourceInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="sourceList" data-app--helpers--search-target="searchList">
                                  <li data-source="base" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Base</li>
                                  <li data-source="referrer" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indicação</li>
                                  <li data-source="landing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Landing</li>
                                  <li data-source="event" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Evento</li>
                                  <li data-source="direct" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Direto</li>
                                  <li data-source="outbound" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outbound</li>
                                  <li data-source="google" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Google</li>
                                  <li data-source="facebook" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Facebook</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Persona</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="kindDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="kindDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="kindInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="kindList" data-app--helpers--search-target="searchList">
                                  <li data-kind="graduated" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recém-Formado</li>
                                  <li data-kind="resident" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Residente</li>
                                  <li data-kind="market" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Especialista</li>
                                  <li data-kind="consulting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultório</li>
                                  <li data-kind="clinic" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Clínica</li>
                                  <li data-kind="non_doctor" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Não Médico</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 px-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Estágio</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="stageDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="stageDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="stageInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="stageList" data-app--helpers--search-target="searchList">
                                  <li data-stage="prospecting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Prospecção</li>
                                  <li data-stage="qualification" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Qualificação</li>
                                  <li data-stage="booking" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Agendamento</li>
                                  <li data-stage="meeting" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Consultoria</li>
                                  <li data-stage="proposal" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Proposta</li>
                                  <li data-stage="closing" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Fechamento</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-8 pl-0 pr-2">
                        <div class="form-group">
                          <div class="floating-label floating-label-sm">
                            <label>Campanha</label>
                            <div class="dropdown dropdown-selector" data-controller="app--helpers--search app--helpers--selector" data-app--helpers--selector-target="dropdown" data-${this.controllerName}-target="campaignDropdown">
                              <button class="dropdown-toggle form-control d-flex" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height:32px;" data-app--helpers--selector-target="btn" data-${this.controllerName}-target="campaignDropdownBtn"><span class="mr-auto w-100 selected-item" data-app--helpers--selector-target="input" data-${this.controllerName}-target="campaignInput"></span></button>
                              <div class="dropdown-menu dropdown-menu-selector w-100 box-shadow-selector">
                                <input class="form-control form-control-selector" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchList" type="text" placeholder="Buscar ...">
                                <ul class="ul-select" data-${this.controllerName}-target="campaignList" data-app--helpers--search-target="searchList">
                                  <li data-campaign="upsell_base" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Upsell Base</li>
                                  <li data-campaign="recommendation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Indicação</li>
                                  <li data-campaign="reactivation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Reativação de Leads</li>
                                  <li data-campaign="graduation" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Recém-Formados 2023</li>
                                  <li data-campaign="outbounding" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Outbound</li>
                                  <li data-campaign="direct_contact" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Contato Direto</li>
                                  <li data-campaign="market_events" data-action="click->app--helpers--selector#select" data-app--helpers--selector-target="select" class="li-selector dark f-065">Eventos de Mercado</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                     
                    </div>

                    <div class="row my-2">
                        <div class="col-12 pl-0 pr-2">
                          <div class="form-group">
                            <div class="floating-label floating-label-sm">
                              <label for="notesForm">Anotações</label>
                              <textarea aria-describedby="notesFormHelp" class="form-control textarea px-0" id="notesForm" data-${this.controllerName}-target="notesInput" placeholder="Anotações" type="text" required rows="4"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                  </div>
                  <div class="card-footer border-top text-right py-1">
                    <button type="button" class="btn btn-secondary btn-sm btn-table f-065" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                    ${saveBtnHtml}
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.gridElement.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.65) + "px"
      var date = new Date()
      controller.startedAtInputTarget.value = controller.getControllerByIdentifier("app--helpers--date").transformPrettyDate(date)
      controller.startedAtInputTarget.disabled = true

      controller.stageInputTarget.innerText = `Prospecção`
      controller.stageInputTarget.dataset.stage = `prospecting`
      controller.stageDropdownTarget.value = `prospecting`
      controller.stageDropdownBtnTarget.disabled = true

      if (controller.current_lead && controller.current_lead.id) {
        controller.viewTitleTarget.innerText = `Nova Oportunidade para ${this.current_lead.name}`

        controller.nameInputTarget.value = controller.current_lead.name
        controller.nameInputTarget.disabled = true

        if (controller.current_radar) {
          controller.sourceInputTarget.innerText = controller.current_radar.source_pretty
          controller.sourceInputTarget.dataset.source = controller.current_radar.source
          controller.sourceDropdownTarget.value = controller.current_radar.source
          controller.sourceDropdownBtnTarget.disabled = true

          controller.kindInputTarget.innerText = controller.current_radar.kind_pretty
          controller.kindInputTarget.dataset.kind = controller.current_radar.kind
          controller.kindDropdownTarget.value = controller.current_radar.kind_pretty

          controller.campaignInputTarget.innerText = controller.current_radar.campaign_pretty
          controller.campaignInputTarget.dataset.campaign = controller.current_radar.campaign
          controller.campaignDropdownTarget.value = controller.current_radar.kind_pretty
        }

        // controller.sourceInputTarget.innerText = controller.current_lead.primary_source_pretty
        // controller.sourceInputTarget.dataset.source = controller.current_lead.primary_source
        // controller.sourceDropdownTarget.value = controller.current_lead.primary_source
        // controller.sourceDropdownBtnTarget.disabled = true

        // controller.notesInputTarget.value = controller.current_lead.notes
      } else {
        controller.viewTitleTarget.innerText = `Nova Oportunidade`
        // controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFutureFromDate($(controller.startedAtInputTarget), "monthly", date.getFullYear(), date.getMonth() + 1, date)
      }

      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
      controller.refreshSaveBtn()
    })
  }

  cancelSave() {
    this.stopRefreshing()
    if (this.controllerDashboard.context.identifier == `commercial--sales--opportunities--radars--dashboard`) {
      this.getControllerByIdentifier(`commercial--sales--opportunities--radars--index`).clearList()
    } else if (this.controllerDashboard.context.identifier == `commercial--sales--leads--entities--dashboard`) {
      this.getControllerByIdentifier(`commercial--sales--leads--entities--list`).clearList()
    }
  }

  saveOpportunity() {
    this.getControllerByIdentifier(`app--helpers--pre-modal`).openProcessModal({ message: `Em processamento, aguarde!` })
    this.actionMode = `new`

    this.saveBtnTarget.disabled = true
    this.stopRefreshing()
    this.send_data = { current_user: {}, lead: {}, opportunity: {}, notification: {}, note: {} }

    this.send_data.lead.id = this.current_lead.id

    this.send_data.opportunity.status = `hot`
    this.send_data.opportunity.stage = this.stageInputTarget.dataset.stage
    this.send_data.opportunity.kind = this.kindInputTarget.dataset.kind
    this.send_data.opportunity.campaign = this.campaignInputTarget.dataset.campaign
    this.send_data.opportunity.source = this.sourceInputTarget.dataset.source
    this.send_data.opportunity.started_at = this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.startedAtInputTarget.value)
    // this.send_data.opportunity.notes = this.current_lead.notes.trim()
    this.send_data.opportunity.notes = this.notesInputTarget.value.trim()

    this.send_data.notification.domain_id = ``
    this.send_data.notification.domain_type = "sales_opportunities"
    this.send_data.notification.date_id = this.application.current_date.id
    this.send_data.notification.date_type = "commercial_dates"
    this.send_data.notification.kind = `mode_${this.actionMode}`
    this.send_data.notification.action = `${this.getControllerByIdentifier("app--visits--users").getUserName(this.application.current_user)} %mode_${this.actionMode}% uma nova Oportunidade de Venda com o Lead ${this.current_lead.name}`
    this.send_data.notification.account_name = this.application.current_user.name
    this.send_data.notification.account_id = this.application.current_user.account_id

    this.send_data.note.account_id = this.application.current_user.account_id
    // this.send_data.note.domain_id = this.application.opportunity.id
    this.send_data.note.domain_type = `sales_opportunities`
    this.send_data.note.domain_name = `sales_opportunities`
    // this.send_data.note.date_id = this.application.opportunity.date_id
    this.send_data.note.date_type = `commercial_dates`
    this.send_data.note.owner_id = this.application.current_user.account_id
    this.send_data.note.owner_name = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()}`
    this.send_data.note.sharing = `internal`
    this.send_data.note.kind = `updating`
    this.send_data.note.date = new Date()
    this.send_data.note.body = `${this.application.current_user.name.split(" ").shift()} ${this.application.current_user.name.split(" ").pop()} criou a Oportunidade de Venda com Lead ${this.current_lead.name} a partir do Radar de Oportunidades. Observações: ${this.notesInputTarget.value.trim()}`
    this.send_data.note.title = `Atualização`

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `sales_opportunities_entities`

    this.requestSave()
  }

  requestSave() {
    var url = "/commercial/sales/opportunities/entities/create"
    var method = "POST"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var opportunity = response.data.cln
          if (controller.controllerDashboard.context.identifier == `commercial--sales--opportunities--radars--dashboard`) {
            controller.updateRadar()
          } else if (controller.controllerDashboard.context.identifier == `commercial--sales--leads--entities--dashboard`) {
            controller.current_lead.opportunities[controller.current_lead.opportunities.length] = opportunity
            controller.current_lead.status = `in_process`
            window.open(opportunity.url, `_blank`)
            controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
          }
        }
        controller.cancelSave()
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  updateRadar() {
    this.radar_send_data = { current_user: {}, radar: {} }

    this.radar_send_data.radar.id = this.current_radar.id
    this.radar_send_data.radar.status = `realized`

    this.radar_send_data.current_user.current_user_id = this.application.current_user.id
    this.radar_send_data.current_user.feature = this.permission

    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").current_radar = this.current_radar
    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").send_data = this.radar_send_data
    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").actionMode = `edit`
    this.getControllerByIdentifier("commercial--sales--opportunities--radars--save").requestSave()
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      if (controller.hasFormCardBodyTarget) {
        var len = 0

        if (controller.nameInputTarget.value == ``) {
          len += 1
        }

        if (controller.startedAtInputTarget.value == ``) {
          len += 1
        }

        if (controller.sourceInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.kindInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.stageInputTarget.innerText == ``) {
          len += 1
        }

        if (controller.campaignInputTarget.innerText == ``) {
          len += 1
        }

        if (len == 0) {
          controller.saveBtnTarget.disabled = false
        } else {
          controller.saveBtnTarget.disabled = true
        }
      } else {
        controller.stopRefreshing()
      }

    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
