import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "view", "viewCard", "viewTitle", "alias", "viewClientCard", "viewClientCardBody", "bodyTable"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerName = `operations--ongoings--customers--index--show`
  }

  doClientHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="viewClientCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3 mr-auto" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle"></h6>
                    <span class="mc-tooltip">
                      <span class="my-0 px-3 pointer" data-${this.controllerName}-target="closeClientCardBtn" data-action="click->${this.controllerName}#closeClientCard">
                        <span class="material-icons md-dark md-200">clear</span>
                      </span>
                      <span class="mc-tooltiptext">Fechar</span>
                    </span>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewClientCardBody">
                    <div class="row mt-3">
                      <div class="col-12 px-1">
                        <h6 class="mb-0 text-center f-075">${this.loader}</h6>
                        <hr class="m-1">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                    </div>
                    <div class="row">
                      <div class="col-12 px-1">
                        <h6 class="mb-0 text-center f-075">${this.loader}</h6>
                        <hr class="m-1">
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                      <div class="col-4 px-1">${this.cardLoader}</div>
                    </div>
                  </div>
                </div>`


    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`operations--ongoings--customers--index--dashboard`).viewTarget.innerHTML = html)
    }).then(() => {
      controller.viewClientCardTarget.style.height = ($(window).height() * 0.6) + "px"
      // controller.viewTitleTarget.innerText = `${this.current_client.account_name} - ${this.current_client.account_cpf_pretty}`
      controller.setClientName()
      controller.readClientProducts()
    })
  }

  setClientName() {

    var clientNumber = `<span class="mc-tooltip grab"> 
                          <span data-controller="app--helpers--copy" data-copy="${this.current_client.account_cpf}" data-action="click->app--helpers--copy#copy">${this.current_client.account_cpf_pretty}</span>
                          <span class="mc-tooltiptext">Clique para Copiar</span>
                        </span>`

    var clientName = `<span class="mc-tooltip grab"> 
                          <span data-controller="app--helpers--copy" data-copy="${this.current_client.account_name}" data-action="click->app--helpers--copy#copy">${this.current_client.account_name}</span>
                          <span class="mc-tooltiptext">Clique para Copiar</span>
                        </span>`

    this.viewTitleTarget.innerHTML = `${clientName} - ${clientNumber}`

  }

  closeClientCard() {
    this.doViewHtml()
  }

  doClientAlias() {

    var opportunitiesHtml = ``
    var productHtml = ``
    var productsHtml = ``

    var emailHtml = ``
    if (this.current_client.account_email) {
      emailHtml = `<span class="f-075 f-bold">E-mail:</span>
                    <span class="mc-tooltip grab">
                      <span data-controller="app--helpers--copy" data-copy="${this.current_client.account_email}" data-action="click->app--helpers--copy#copy">${this.current_client.account_email}</span>
                      <span class="mc-tooltiptext">Clique para Copiar</span>
                    </span>`
    } else {
      emailHtml = `<span class="f-075 f-bold">E-mail:</span> Sem E-mail cadastrado. Pedir para o CS atualizar.`
    }

    var phoneHtml = ``
    if (this.current_client.account_phone) {
      // phoneHtml = `<span class="f-075 f-bold">Telefone:</span>
      //               <span class="mc-tooltip grab">
      //                 <span data-controller="app--helpers--copy" data-copy="${this.current_client.account_phone}" data-action="click->app--helpers--copy#copy">${this.current_client.account_phone}</span>
      //                 <span class="mc-tooltiptext">Clique para Copiar</span>
      //               </span>`
      phoneHtml = `<span class="f-075 f-bold">Telefone:</span>
                    <span class="mc-tooltip">
                      <span data-action="click->${this.controllerName}#sendWhatsAppApi">${this.current_client.account_phone}</span>
                      <span class="mc-tooltiptext">Enviar WhatsApp</span>
                    </span>`
    } else {
      phoneHtml = `<span class="f-075 f-bold">Telefone:</span> Sem Telefone cadastrado. Pedir para o CS atualizar.`
    }

    var contactsHtml = ``
    contactsHtml += `<div class="row my-3 pointer">
                        <div class="col-12 px-1 f-075 mb-3">${emailHtml}</div>
                        <div class="col-12 px-1 f-075 mb-3">${phoneHtml}</div>
                      </div>`

    if (this.current_client.has_products) {
      if (this.current_client.has_tax_return) {
        productHtml += `<span class="badge badge-primary mx-2">Planner</span>`
      }
      if (this.current_client.has_booking) {
        productHtml += `<span class="badge badge-primary mx-2">Livro-Caixa</span>`
      }
      if (this.current_client.has_clinic) {
        productHtml += `<span class="badge badge-primary mx-2">PJ Médica</span>`
      }
      if (this.current_client.has_billing) {
        productHtml += `<span class="badge badge-primary mx-2">Faturamento</span>`
      }
      if (this.current_client.has_receivement) {
        productHtml += `<span class="badge badge-primary mx-2">Gestão de Recebimentos</span>`
      }
      if (this.current_client.has_tax_filing) {
        productHtml += `<span class="badge badge-primary mx-2">Declaração IRPF</span>`
      }
      if (this.current_client.has_banking) {
        productHtml += `<span class="badge badge-primary mx-2">Conta Bancária</span>`
      }

      productsHtml += `<div class="row">
                        <div class="col-12 px-1 f-075">${this.current_client.account_name} é Cliente nos Serviços:</div>
                      </div>
                      <div class="row my-3">
                        ${productHtml}
                      </div>`
    } else {
      productsHtml += `<div class="row ">
                        <div class="col-12 px-1 f-075">${this.current_client.account_name} ainda não é Cliente</div>
                      </div>`
    }

    var opportunityHtml = ``
    opportunityHtml += `<div class="row my-3 pointer">
                          <span class="badge badge-success mx-2" data-account-id="${this.current_client.account_id}" data-kind="client" data-action="click->${this.controllerName}#addOpportunity">Nova Oportunidade</span>
                        </div>`

    var partnersHtml = ``
    if (this.current_client.has_clinic) {
      var partnersTableHtml = this.partnerDataTable()
      partnersHtml += `<div class="row my-2">
                        <div class="col-12 px-1 f-075 mb-3">E possui o(s) seguinte(s) sócio(s):</div>
                        <div class="col-12 px-1 f-075">${partnersTableHtml}</div>
                      </div>`
    }

    var clinicsHtml = ``
    if (this.current_client.has_clinic) {
      var clinicsTableHtml = this.clinicDataTable()
      clinicsHtml += `<div class="row my-2">
                        <div class="col-12 px-1 f-075 mb-3">É sócio da(s) seguinte(s) empresas(s):</div>
                        <div class="col-12 px-1 f-075">${clinicsTableHtml}</div>
                      </div>`
    }

    var html = `${contactsHtml}
                ${productsHtml}
                ${opportunityHtml}
                ${clinicsHtml}
                ${partnersHtml}`

    this.viewClientCardBodyTarget.innerHTML = html
  }

  partnerDataTable() {
    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search">
                  <div class="card-body py-0 card-overflow">
                    <div class="row">
                      <div class="col p-0">
                        <div class="mc-table-responsive">
                          <table class="table table-sm mc-table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-25 align-middle pointer">
                                  Nome
                                  <span class="material-icons md-sm md-dark" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark d-none" data-action="click->${this.controllerName}#sortTable" data-field="account_name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">IRPF</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">LC</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">DP</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-10 align-middle text-center">GR</th>
                              </tr>
                            </thead>
                            <tbody>
                              ${this.doDataTable()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doDataTable() {
    var current_partners = []
    current_partners = this.current_client.partners
    var partners = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_partners, `name`)
    var html = ``

    partners.forEach(element => {
      html += this.partnerTablePartial(element, partners.length)
    })

    return html
  }

  partnerTablePartial(element, length) {

    if (element.has_tax_return) {
      var returnIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var returnIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_booking) {
      var bookingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var bookingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_clinic) {
      var legalIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var legalIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_billing) {
      var billingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var billingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_receivement) {
      var receivementIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var receivementIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_tax_filing) {
      var filingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var filingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_banking) {
      var bankingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var bankingIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }
    if (element.has_payroll) {
      var payrollIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-success">done</span>
                          <span class="mc-tooltiptext">É Cliente</span>
                        </span>`
    } else {
      var payrollIcon = `<span class="mc-tooltip">
                          <span class="material-icons md-sm md-danger">clear</span>
                          <span class="mc-tooltiptext">Não Tem</span>
                        </span>`
    }


    if (length == 1) {
      var tableRow = `<tr class="itemRow" style="height:50px;">`
    } else {
      var tableRow = `<tr class="itemRow">`
    }


    var rowHtml = `${tableRow}
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-account-id="${element.account_id}" data-kind="partner" data-action="click->${this.controllerName}#addOpportunity">
                      <span class="mb-0 f-065 mc-tooltip">
                        <span>${element.account_name}</span>
                        <span class="mc-tooltiptext mc-tooltiptext-right">PJ: ${element.clinic_name}</span>
                      </span>
                    </td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${filingIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${bookingIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${payrollIcon}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer text-center">${receivementIcon}</td>
                  </tr>`

    return rowHtml
  }

  clinicDataTable() {
    var html = `<div class="card card-no-shadow" style="width:100%;display:relative;" data-controller="app--helpers--search">
                  <div class="card-body py-0 card-overflow">
                    <div class="row">
                      <div class="col p-0">
                        <div class="mc-table-responsive">
                          <table class="table table-sm mc-table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-70 align-middle pointer">Empresa</th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-30 align-middle pointer">CNPJ</th>
                              </tr>
                            </thead>
                            <tbody>
                              ${this.doClinicDataTable()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

    return html
  }

  doClinicDataTable() {
    var current_clinics = []
    current_clinics = this.current_client.clinics
    var clinics = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_clinics, `name`)
    var html = ``

    clinics.forEach(element => {
      if (clinics.length == 1) {
        var tableRow = `<tr class="itemRow" style="height:50px;">`
      } else {
        var tableRow = `<tr class="itemRow">`
      }


      html += `${tableRow}
                  <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                    <p class="mb-0 f-065 mc-tooltip grab">
                      <span data-controller="app--helpers--copy" data-copy="${element.company_name}" data-action="click->app--helpers--copy#copy">${element.company_name}</span>
                      <span class="mc-tooltiptext">Clique para Copiar</span>
                    </p>
                  </td>
                  <td style="font-size:80%;" scope="col" class="p-1 align-middle">
                    <p class="mb-0 f-065 mc-tooltip grab">
                      <span data-controller="app--helpers--copy" data-copy="${element.company_cnpj}" data-action="click->app--helpers--copy#copy">${element.company_cnpj_pretty}</span>
                      <span class="mc-tooltiptext">Clique para Copiar</span>
                    </p>
                  </td>
                </tr>`
    })

    return html
  }

  doViewHtml() {
    var html = `<div class="card" style="width:100%;display:relative;">
                  <div class="card-header d-flex align-items-center card-header-table-list f-065">
                    <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="viewTitle">Nenhum cliente Selecionado</h6>
                  </div>
                  <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="viewBankCardBody"></div>
                </div>`

    this.getControllerByIdentifier(`operations--ongoings--customers--index--dashboard`).viewTarget.innerHTML = html
  }

  addOpportunity(ev) {
    var modalName = `commercial--sales--opportunities--entities--save-modal`
    var kind = ev.currentTarget.dataset.kind
    var accountId = ev.currentTarget.dataset.accountId

    var html = `<div class="modal fade" data-controller="${modalName}" data-${modalName}-target="modal" id="modal" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-body p-0" data-${modalName}-target="body"></div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(document.body.insertAdjacentHTML("beforeend", html))
    }).then(() => {
      var current_obj = {}
      if (kind == `client`) {
        current_obj.id = this.current_client.account_id
        current_obj.account_id = this.current_client.account_id
        current_obj.account_email = this.current_client.account_email
        current_obj.account_name = this.current_client.account_name
      } else if (kind == `partner`) {
        this.current_client.partners.forEach(element => {
          if (element.account_id == accountId) {
            current_obj.id = element.account_id
            current_obj.account_id = element.account_id
            current_obj.account_email = element.account_email
            current_obj.account_name = element.account_name
          }
        })
      }
      
      current_obj.type = `account_entities`
      current_obj.date_id = ``
      current_obj.observations = `a partir do Relacionamento com a Base`

      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).current_lead = {}
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).current_obj = current_obj
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).permission = `operations_ongoing_opportunities`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).title = `Nova Oportunidade para ${controller.current_client.account_name} CPF: ${controller.current_client.account_cpf_pretty}`
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).controllerForm = controller
      controller.getControllerByIdentifier(`commercial--sales--opportunities--entities--save-modal`).open()
    })
  }

  readClientProducts() {
    const data = { account: { account_id: this.account_id }, current_user: { current_user_id: this.application.current_user.id, feature: `operations_ongoing_accounts` } }
    const url = "/operations/accounts/products/read_with_account_and_partners"

    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.current_client = response.data.cln

        controller.doClientAlias()
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendWhatsAppApi(ev) {

    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_id = this.current_client.id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_type = `operation_account_products`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).record_name = `Relacionamento Ongoing ${this.current_client.account_name}`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_id = this.current_client.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).obj_type = `account_entities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_id = this.current_client.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_type = `account_entities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).person_name = this.current_client.account_name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_id = this.application.current_user.account_id
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).account_name = this.application.current_user.name
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).ddd = this.current_client.account_whatsapp.slice(0,2)
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).number = this.current_client.account_whatsapp.slice(2,11)
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).uniq_number = this.current_client.account_whatsapp
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).kind = `whatsapp`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).subkind = `active`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).permission = `operations_ongoing_opportunities`
    this.getControllerByIdentifier(`app--helpers--whatsapp-api`).requestSaveInteraction()
  }

  clearList() {

  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_blank`)
  }


  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["viewClientCard"]
    var identifier = `operations--ongoings--customers--index--show`
    this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}