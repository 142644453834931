import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "content", "addWeeklyBtn", "addTopicBtn", "addTicketBtn", "addNoteBtn", "listTopics", "listWeeklies", "weeklyAddCard",
                    "dateInput", "timetableInput", "durationInput", "saveWeeklyBtn", "listNotes", "listTickets", "ticketsRow", "notesRow",
                    "ticketsCard", "notesCard", "weeklyAddCardTitle", "inProcess", "resolved"]

  connect() {
    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.controllerName = `users--works--meetings--boards--dashboard`
    this.doGridHtml()
  }

  setCurrentWeekly() {
    var weeklies = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.weeklies, "date_to_time")
    this.application.current_weekly = weeklies[0]
  }

  updateCurrentWeekly(weeklyId) {
    this.application.weeklies.forEach(element => {
      if (element.id == weeklyId) {
        this.application.current_weekly = element
      }
    })
    this.setWeeklies()
    this.getControllerByIdentifier(`users--works--meetings--boards--topics`).doTopicHtml()
    this.getControllerByIdentifier(`users--works--meetings--boards--show`).loadDateData()
    // this.getControllerByIdentifier(`users--works--meetings--boards--tickets`).doDataTable()
    // this.getControllerByIdentifier(`users--works--meetings--boards--notes`).setNotes()
  }

  changeCurrentWeekly(ev) {
    var weeklyId = ev.currentTarget.closest(".weeklyCard").dataset.id
    this.updateCurrentWeekly(weeklyId)
  }

  addTicket() {
    this.getControllerByIdentifier("users--works--meetings--boards--tickets").createTicket()
  }

  refreshTicket() {
    this.getControllerByIdentifier("users--works--meetings--boards--tickets").doDataTable()
  }

  addNote() {
    this.getControllerByIdentifier("users--works--meetings--boards--notes").createNote()
  }

  refreshNote() {
    this.getControllerByIdentifier("users--works--meetings--boards--notes").setNotes()
  }

  setWeeklies() {
    var weeklies = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(this.application.weeklies, "date_to_time")

    if (weeklies.length == 0) {
      var html = `<div class="row my-2 w-100 weeklyCard">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-body px-0 py-2 f-065 pointer">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-9 px-1">
                            Adicione a 1ª Reunião Semanal
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`

      this.listWeekliesTarget.innerHTML = html
    } else {
      this.listWeekliesTarget.innerHTML = ``
      weeklies.forEach(element => {
        this.listWeekliesTarget.insertAdjacentHTML("beforeend", this.weeklyPartial(element))
      });
    }

    // weeklies.forEach(element => {
    // })

    // this.listWeekliesTarget.innerHTML = html
  }

  weeklyPartial(element) {
    if (element.id == this.application.current_weekly.id) {
      var cardClass = `<div class="card bg-primary">`
    } else {
      var cardClass = `<div class="card">`
    }

    if (this.application.permissions.work_meeting_weeklies.can_update && this.application.board.leader_id == this.application.current_user.account_id) {
      var editBtn = `<button data-action="click->${this.controllerName}#editWeekly" data-id="${element.id}" type="button" class="btn btn-sm btn-table p-0 mc-tooltip btn-secondary">
                        <span class="material-icons md-sm md-dark">edit</span>
                        <span class="mc-tooltiptext">Editar</span>
                      </button>`
    } else {
      var editBtn = ``
    }

    var html = `<div class="row my-2 w-100 weeklyCard" data-id="${element.id}" data-${this.controllerName}-target="card-${element.id}">
                  <div class="col-12 px-1">
                      ${cardClass}
                      <div class="card-body px-0 py-2 f-065 pointer">
                        <div class="row my-2 d-flex align-items-center">
                          <div class="col-9 px-1" data-action="click->${this.controllerName}#changeCurrentWeekly">
                            <div class="card-show-dropdown">
                              <span>${element.name}</span>
                              <div class="card-show-dropdown-content text-left">
                                <p class="mb-0 f-065"><span class="material-icons md-sm md-dark">timer</span> ${element.timetable} min</p>
                                <p class="mb-0 f-065"><span class="material-icons md-sm md-dark">hourglass_top</span> ${element.duration} min</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-3 px-1">
                            ${editBtn}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`


    return html
  }

  addWeekly() {
    this.actionMode = `new`
    this.doWeeklyHtml()
  }

  editWeekly(ev) {
    this.actionMode = `edit`

    this.application.weeklies.forEach(element => {
      if (element.id == ev.currentTarget.closest(".weeklyCard").dataset.id) {
        this.edit_weekly = element
      }
    })

    this.doWeeklyHtml()
  }

  doWeeklyHtml() {

    var html = `<div class="row my-2 w-100"  data-${this.controllerName}-target="weeklyAddCard">
                  <div class="col-12 px-1">
                    <div class="card">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-1" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="weeklyAddCardTitle"></h6>
                      </div>
                      <div class="card-body p-0 f-065 text-center pointer">
                        <div class="row d-flex align-items-center">
                          <div class="col-7 px-2">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dateForm">Data</label>
                                <input aria-describedby="dateFormHelp" class="form-control form-valid-control" id="dateForm" data-filter-mode="simple" data-${this.controllerName}-target="dateInput" placeholder="Data" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-5 px-2">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="timetableForm">Horário</label>
                                <input aria-describedby="timetableFormHelp" class="form-control form-valid-control" id="timetableForm" data-filter-mode="simple" data-${this.controllerName}-target="timetableInput" data-action="keypress->${this.controllerName}#changeToTimetable" placeholder="Horário" type="text" required>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center">
                          <div class="col-8 px-2">
                            <div class="form-group form-valid-group">
                              <div class="floating-label floating-label-sm">
                                <label for="durationForm">Duração</label>
                                <div class="input-group mb-3">
                                  <input aria-describedby="durationFormHelp" class="form-control form-valid-control" id="durationForm" data-filter-mode="simple" data-${this.controllerName}-target="durationInput" placeholder="Duração" type="text" required>
                                  <div class="input-group-append">
                                    <span class="input-group-text f-065">min</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm p-1 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        <button disabled type="button" class="btn btn-primary btn-sm p-1 btn-table f-065" data-action="click->${this.controllerName}#saveWeekly" data-${this.controllerName}-target="saveWeeklyBtn">Salvar</button>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listWeekliesTarget.insertAdjacentHTML("afterbegin", html))
    }).then(() => {
      var date = new Date()
      controller.getControllerByIdentifier("app--helpers--pickdate").pickOnlyFuture($(controller.dateInputTarget), "yearly", date.getFullYear())
      controller.refreshWeeklySaveBtn()

      if (controller.actionMode == `new`) {
        controller.weeklyAddCardTitleTarget.innerHTML = `<strong>Novo 1:1</strong>`
      } else if (controller.actionMode == `edit`) {
        controller.weeklyAddCardTitleTarget.innerHTML = `<strong>Editando 1:1</strong>`

        controller.dateInputTarget.value = controller.edit_weekly.date_pretty
        controller.timetableInputTarget.value = controller.edit_weekly.timetable
        controller.durationInputTarget.value = controller.edit_weekly.duration
      }
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  changeToTimetable(ev) {
    this.getControllerByIdentifier(`app--helpers--numbers`).changeNumberToTimetable(ev)
  }

  cancelSave() {
    this.stopRefreshing()
    this.weeklyAddCardTargets.forEach(element => {
      element.remove()
    })
  }

  saveWeekly() {
    this.send_data = { weekly: {}, current_user: {} }
    var weeklyDate = new Date(this.getControllerByIdentifier("app--helpers--date").transformFullDate(this.dateInputTarget.value))

    if (this.actionMode == `edit`) {
      this.send_data.weekly.id = this.edit_weekly.id  
    } else if (this.actionMode == `new`) {
      this.send_data.weekly.board_id = this.application.board.id
    }
    this.send_data.weekly.date = weeklyDate
    this.send_data.weekly.timetable = this.timetableInputTarget.value
    this.send_data.weekly.duration = this.durationInputTarget.value
    this.send_data.weekly.week_day = weeklyDate.getDay()

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_weeklies`

    this.requestSaveWeekly()
  }

  requestSaveWeekly() {

    if (this.actionMode == "new") {
      var url = "/users/works/meetings/weeklies/create"
      var method = "POST"
    } else if (this.actionMode == "edit") {
      var url = "/users/works/meetings/weeklies/update"
      var method = "PUT"
    }
    var controller = this
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.stopRefreshing()
        if (response.save) {
          var weekly = response.data.cln
          controller.sendWeeklyEmailNotification(weekly)
          controller.cancelSave()
          // if (controller.actionMode == "new") {
          //   controller.application.weeklies[controller.application.weeklies.length] = weekly
          // } else if (controller.actionMode == "edit") {
          //   if (weekly.active) {
          //     controller.application.weeklies.forEach((element, i) => {
          //       if (element.id == weekly.id) {
          //         controller.application.weeklies.splice(controller.application.weeklies.indexOf(element), 1, weekly)
          //       }
          //     })
          //   } else {
          //     controller.application.weeklies.forEach((element, i) => {
          //       if (element.id == weekly.id) {
          //         controller.application.weeklies.splice(controller.application.weeklies.indexOf(element), 1)
          //       }
          //     })
          //   }
          // }
          
        } else {
          if (controller.hasSaveBtnTarget) {
            controller.saveBtnTarget.disabled = false
          }
          if (controller.hasDeleteBtnTarget) {
            controller.deleteBtnTarget.disabled = true
          }
        }
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  sendWeeklyEmailNotification(weekly) {
    this.send_data = { current_user: {}, notification: {} }

    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `work_meeting_weeklies`

    this.send_data.notification.weekly_id = weekly.id
    this.send_data.notification.board_id = this.application.board.id
    this.send_data.notification.board_path = this.application.board.slug
    this.send_data.notification.board_name = `Semanal ${this.application.board.name}: ${weekly.name}`
    this.send_data.notification.receiver_id = this.application.board.team_id
    this.send_data.notification.receiver_name = this.application.board.team_name
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.body = `${weekly.name} às ${weekly.timetable}`
    this.send_data.notification.action_mode = this.actionMode

    if (this.actionMode == "new") {
      this.send_data.notification.action = `criou o Semanal ${this.application.board.name} para`
    } else if (this.actionMode == "edit") {
      this.send_data.notification.action = `atualizou o Semanal ${this.application.board.name} para`
    }

    var url = "/users/works/meetings/weeklies/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).finalResponse(response)
      })
      .catch(error => {
        controller.getControllerByIdentifier(`app--helpers--fetch-response`).catchError(error)
      })
  }

  toggleTicketNote(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "ticket") {
      this.ticketsCardTarget.classList.add("bg-primary")
      this.notesCardTarget.classList.remove("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      
    } else if (feature == "note") {
      this.ticketsCardTarget.classList.remove("bg-primary")
      this.notesCardTarget.classList.add("bg-primary")
      this.ticketsRowTargets.forEach(element => {
        element.classList.add("d-none")
      })
      this.notesRowTargets.forEach(element => {
        element.classList.remove("d-none")
      })
    }
  }

  doGridHtml() {
    // <div class="card-body py-1 px-1" style="overflow:auto;" data-${this.controllerName}-target="regimeView">
    
    var html = `<div class="row">
                  <div class="col-8 px-2"></div>
                  <div class="col-4 px-2">
                    <div class="row my-2 w-100">
                      <div class="col-6 px-1">
                        <div class="card bg-primary pointer" data-${this.controllerName}-target="ticketsCard" data-feature="ticket" data-action="click->${this.controllerName}#toggleTicketNote">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Tickets</strong></h6>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 px-1">
                        <div class="card pointer" data-${this.controllerName}-target="notesCard" data-feature="note" data-action="click->${this.controllerName}#toggleTicketNote">
                          <div class="card-header py-0 f-065 text-center">
                            <h6 class="card-title py-1" style="font-size:110%;margin-bottom:0px;"><strong>Anotações</strong></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-2 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>1:1s</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addWeekly" data-${this.controllerName}-target="addWeeklyBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar 1:1</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="card-body py-1 px-1" data-${this.controllerName}-target="listWeeklies" style="overflow:auto;">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-6 px-2">
                    <div class="row">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Tópicos</span>
                          <span class="mc-tooltip ml-auto pointer d-none" data-action="click->${this.controllerName}#addTopic" data-${this.controllerName}-target="addTopicBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Tópico</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row h-100" data-${this.controllerName}-target="listTopics" data-users--works--meetings--boards--topics-target="main" data-controller="users--works--meetings--boards--topics">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                  <div class="col-4 px-2">
                    <div class="row" data-${this.controllerName}-target="ticketsRow">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Tickets</span>
                          <span class="f-075 pointer badge badge-secondary ml-auto" data-${this.controllerName}-target="inProcess" data-feature="in_process" data-action="click->${this.controllerName}#toggleTickets">Em Aberto</span>
                          <span class="f-075 pointer badge badge-secondary-dark" data-${this.controllerName}-target="resolved" data-feature="resolved" data-action="click->${this.controllerName}#toggleTickets">Resolvidos</span>
                          <span class="mc-tooltip ml-2 pointer" data-action="click->${this.controllerName}#refreshTicket">
                            <span class="material-icons md-sm md-dark">refresh</span>
                            <span class="mc-tooltiptext">Atualizar Tickets</span>
                          </span>
                          <span class="mc-tooltip ml-2 pointer d-none" data-action="click->${this.controllerName}#addTicket" data-${this.controllerName}-target="addTicketBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Ticket</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                      </div>
                    </div>
                    <div class="row" data-${this.controllerName}-target="ticketsRow" data-users--works--meetings--boards--tickets-target="ticketBoard" data-controller="users--works--meetings--boards--tickets">
                      <span class="w-100">${this.loader}</span>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="notesRow">
                      <div class="col-12 px-0">
                        <h6 class="mb-0 d-flex align-items-center">
                          <span>Anotações</span>
                          <span class="mc-tooltip ml-auto pointer" data-action="click->${this.controllerName}#refreshNote">
                            <span class="material-icons md-sm md-dark">refresh</span>
                            <span class="mc-tooltiptext">Atualizar Anotações</span>
                          </span>
                          <span class="mc-tooltip pointer ml-2 d-none" data-action="click->${this.controllerName}#addNote" data-${this.controllerName}-target="addNoteBtn">
                            <span class="material-icons md-sm md-dark">add</span>
                            <span class="mc-tooltiptext">Adicionar Anotação</span>
                          </span>
                        </h6>
                        <hr class="my-1">
                        </div>
                    </div>
                    <div class="row d-none" data-${this.controllerName}-target="notesRow" data-users--works--meetings--boards--notes-target="list" data-controller="users--works--meetings--boards--notes">
                      <span class="w-100">${this.loader}</span>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier(`users--works--meetings--boards--show`).contentTarget.innerHTML = html)
    }).then(() => {
      // controller.listTicketsTarget.style.height = ($(window).height() * 0.35) + "px"
      controller.listWeekliesTarget.style.height = ($(window).height() * 0.5) + "px"
    })
  }

  toggleTickets(ev) {
    var feature = ev.currentTarget.dataset.feature

    if (feature == "in_process") {
      this.inProcessTarget.classList.add("badge-secondary")
      this.inProcessTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary")

      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.in_process_tickets, `due_at`)
      this.getControllerByIdentifier(`users--works--meetings--boards--tickets`).listData(tickets)
    } else if (feature == "resolved") {
      this.inProcessTarget.classList.remove("badge-secondary")
      this.inProcessTarget.classList.add("badge-secondary-dark")
      this.resolvedTarget.classList.remove("badge-secondary-dark")
      this.resolvedTarget.classList.add("badge-secondary")

      var tickets = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(this.application.resolved_tickets, `due_at`)
      this.getControllerByIdentifier(`users--works--meetings--boards--tickets`).listData(tickets)
    }
  }

  setPageTitle() {
    this.getControllerByIdentifier(`users--works--meetings--boards--show`).boardNameTarget.innerText = `1:1 | ${this.application.board.leader_name} | ${this.application.board.team_name}`

    if (this.application.permissions.work_meeting_weeklies.can_create && (this.application.current_user.account_id == this.application.board.leader_id)) {
      this.addWeeklyBtnTarget.classList.remove("d-none")
    }

    if (this.application.permissions.work_meeting_tickets.can_create) {
      this.addTicketBtnTarget.classList.remove("d-none")
    }

    if (this.application.permissions.work_meeting_notes.can_create) {
      this.addNoteBtnTarget.classList.remove("d-none")
    }
  }

  refreshWeeklySaveBtn() {
    var controller = this
    this.refreshWeeklyTimer = setInterval(function () {
      var len = 0
      
      if (controller.dateInputTarget.value == ``) {
        len += 1
      }

      if (controller.durationInputTarget.value == ``) {
        len += 1
      }

      if (controller.timetableInputTarget.value == ``) {
        len += 1
      }


      if (len == 0) {
        controller.saveWeeklyBtnTarget.disabled = false
      } else {
        controller.saveWeeklyBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshWeeklyTimer) {
      clearInterval(this.refreshWeeklyTimer)
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }
}