import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["main", "list", "view", "viewCard", "viewTitle", "mainCard", "bodyTable", "footerTable", "submenu",
                    "content", "onboardPartyName", "indicators", "purchasesTable", "5555", "6666", "7777", "8888", "9999",
                    "medclinicCard", "medbookingCard", "medfilingCard", "medpayrollCard", "medreceivementCard", "medbilingCard",
                    "medclinicHeaderCard", "medbookingHeaderCard", "medfilingHeaderCard", "medpayrollHeaderCard", "medreceivementHeaderCard", "medbilingHeaderCard"]

  connect() {
    this.controllerName = `operations--onboards--reports--purchases`

    this.loader = this.getControllerByIdentifier("app--helpers--loaders").loader()
    this.cardLoader = this.getControllerByIdentifier("app--helpers--loaders").cardLoader()
    this.controllerNumber = this.getControllerByIdentifier(`app--helpers--numbers`)
    this.itemsOnPage = 300
    this.pageNumber = 0
    this.tableRow = 11
    this.numberPages = []
    this.sort = { mode: `desc`, field: `purchase_started_at_to_time` }
    this.hasFilter = false
    this.filter = ``
  }

  doPurchasesReportHTML() {
    var loader = `<div class='card timeline-item-100 m-0 p-1 w-100' style="box-shadow:none;"><div class='animated-background animated-background-20'><div class='background-masker title'></div></div></div>`

    var html = `<div class="row my-3">
                  <div class="col-12 pl-2 pr-2" data-${this.controllerName}-target="list">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="mainCard">
                      <div class="card-header py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>  
                      </div>
                      <div class="card-body">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                      <div class="card-footer py-0">
                        <div class="row my-2"><div class="col">${loader}</div></div>
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.purchasesTableTarget.innerHTML = html)
      // resolve(controller.getControllerByIdentifier("operations--onboards--reports--main").purchasesTableTarget.innerHTML = html)
    }).then(() => {
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.getPurchaseReport()
      // if (controller.application.purchase_report) {
      //   controller.doIndexListHtml()
      //   controller.doCalculation()
      // }
    })
  }

  doIndexListHtml() {

    var html = `<div class="card" style="width:100%;display:relative;" data-controller="app--helpers--search" data-${this.controllerName}-target="mainCard" data-action="resize@window->${this.controllerName}#layout">
                  <div class="card-header d-flex align-items-center py-0 f-065">
                    <h6 class="card-title display-4 py-0 mb-0 f-085">Todas as Contratações x Onboards</h6>
                    <input class="form-control f-075 w-50 mx-auto" data-app--helpers--search-target="searchInput" data-action="keyup->app--helpers--search#doSearchTable" id="" placeholder="Buscar ..." type="text">
                    <div class="card-actions ml-auto py-2 d-flex align-items-center">
                      <span class="mc-tooltip ml-auto mr-2 pointer" data-action="click->${this.controllerName}#refreshPurchases">
                        <span class="material-icons md-200 md-dark">refresh</span>
                        <span class="mc-tooltiptext">Atualizar as Contratações</span>
                      </span>
                    </div>
                  </div>
                  <div class="card-body py-0" style="overflow:auto;">
                    <div class="row">
                      <div class="col p-0">
                        <div class="table-responsive">
                          <table class="table table-sm table-hover" style="font-size:80%;" data-app--helpers--search-target="searchTable">
                            <thead>
                              <tr>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle">
                                  Início Contratação
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="purchase_started_at_to_time" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="purchase_started_at_to_time" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-20 align-middle">
                                  Partes Envolvidas
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="name" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Produto
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_accrual_name_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Serviço
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="product_service_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Canal
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="purchase_channel_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="purchase_channel_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Jornada Contratação
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Onboard
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Início Onboard
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-8 align-middle text-center">
                                  Jornada Onboard
                                  <span class="material-icons md-sm md-dark pointer" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="desc">south</span>
                                  <span class="material-icons md-sm md-dark pointer d-none" data-action="click->${this.controllerName}#sortTable" data-field="journey_status_pretty" data-mode="asc">north</span>
                                </th>
                                <th style="font-size:80%;" scope="col" class="p-1 table-3 align-middle"></th>
                              </tr>
                            </thead>
                            <tbody data-${this.controllerName}-target="bodyTable">
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer py-0" data-${this.controllerName}-target="footerTable">
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.listTarget.innerHTML = html)
    }).then(() => {
      controller.getControllerByIdentifier("app--helpers--elements").tooltip()
      controller.mainCardTarget.style.height = ($(window).height() * 0.5) + "px"
      controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(this.tableRow, 4))
      controller.doDataTable()
    })
  }

  doDataTable() {
    var current_report = []
    if (this.hasFilter) {
      this.application.purchase_report.forEach(element => {
        if (element.product_name == this.filter) {
          current_report[current_report.length] = element
        }
      })
    } else {
      current_report = this.application.purchase_report
    }

    if (this.sort.mode == `asc`) {
      var report = this.getControllerByIdentifier("app--helpers--data").sortByKeyAsc(current_report, this.sort.field)
    } else if (this.sort.mode == `desc`) {
      var report = this.getControllerByIdentifier("app--helpers--data").sortByKeyDesc(current_report, this.sort.field)
    }

    if (report.length == 0) {
      this.listData()
    } else {
      var itemsOnPage = this.itemsOnPage
      var pageNumber = this.pageNumber
      var numberPages = this.numberPages
      var pages = []
      var itemLoop = Math.ceil(report.length / itemsOnPage)
      var x = 0
      var i = 1
      for (i; i < itemLoop + 1; i++) {
        var internPage = []
        for (x; x < itemsOnPage * i; x++) {
          if (report[x] !== undefined) {
            internPage[internPage.length] = report[x]
          }
        }
        pages[pages.length] = internPage
        numberPages[numberPages.length] = i - 1
      }

      var fromItem = itemsOnPage * pageNumber + 1
      var toItem = Math.min(itemsOnPage * (pageNumber + 1), (itemsOnPage * pageNumber) + pages[pageNumber].length)
      var pagination = { itemsOnPage: itemsOnPage, fromItem: fromItem, toItem: toItem, totalItems: report.length }
      this.listData(pages[pageNumber])
      this.doFooterTable(pagination)
    }
  }

  listData(data) {
    this.bodyTableTarget.innerHTML = ""
    if (data == undefined || data == [] || data.length == 0) {
      var noData = `</tr>
                      <td colspan="${this.tableRow}" class="p-3 align-middle text-center" style="font-size:100%;">
                        <span class="fa-stack fa-1x">
                          <i class="fas fa-list fa-stack-1x"></i>
                        </span>
                        <span class="f-075 align-middle">Não há Contratações em Andamento</span>
                      </td>
                    </tr>`

      this.bodyTableTarget.innerHTML = noData
    } else {
      data.forEach(element => {
        this.bodyTableTarget.insertAdjacentHTML("beforeend", this.onboardTablePartial(element))
      });
    }
    this.getControllerByIdentifier("app--helpers--elements").tooltip()
  }

  onboardTablePartial(element) {

    element.name = ``
    element.parties.forEach(party => {
      element.name += `<p class="mb-0 f-065 f-bold" data-${this.controllerName}-target="onboardPartyName">${party}</p>`
    })

    if (element.has_onboard) {
      var onboard = `<span class="mc-tooltip pointer">
                      <span class="material-icons md-sm md-success">done</span>
                      <span class="mc-tooltiptext">Tem Onboard</span>
                    </span>`
      var onboardStartedAtPretty = element.onboard_started_at_pretty
      var onboardJourneyStatusPretty = element.onboard_journey_status_pretty
      var onboardCreateBtn = ``
    // } else if (element.product_service != `renewal` && element.product_service != `cancellation` && element.product_service != `additive`) {
    } else if (this.checkProductServiceOnboards(element)) {
      var onboard = `<span class="mc-tooltip pointer">
                      <span class="material-icons md-sm md-danger">clear</span>
                      <span class="mc-tooltiptext">Não Tem Onboard</span>
                    </span>`
      var onboardStartedAtPretty = `Não tem Onboard`
      var onboardJourneyStatusPretty = `Não tem Onboard`
      var onboardCreateBtn = `<button data-action="click->${this.controllerName}#createOnboard" type="button" class="btn btn-sm btn-table p-0 mc-tooltip">
                              <span class="material-icons md-sm md-dark">flight_land</span>
                              <span class="mc-tooltiptext">Criar Onboard</span>
                            </button>`
    } else {
      var onboard = `<span class="mc-tooltip pointer">
                      <span class="material-icons md-sm md-danger">clear</span>
                      <span class="mc-tooltiptext">Não Tem Onboard</span>
                    </span>`
      var onboardStartedAtPretty = `Não tem Onboard`
      var onboardJourneyStatusPretty = `Não tem Onboard`
      var onboardCreateBtn = ``
    }

    var rowHtml = `<tr class="itemRow" data-purchase-id="${element.purchase_id}" data-token="${element.token}">
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle">${element.purchase_started_at_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle pointer" data-action="click->${this.controllerName}#goToShowPage">${element.name}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_accrual_name_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.product_service_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.purchase_channel_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${element.purchase_journey_status_pretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${onboard}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${onboardStartedAtPretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${onboardJourneyStatusPretty}</td>
                    <td style="font-size:80%;" scope="col" class="p-1 align-middle text-center">${onboardCreateBtn}</td>
                  </tr>`

    return rowHtml
  }

  itemsLength(ev) {
    this.itemsOnPage = ev.target.dataset.items
    this.pageNumber = 0
    this.numberPages = []
    this.doDataTable()
  }

  previousTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber - 1)) {
      this.pageNumber -= 1
      this.doDataTable()
    }
  }

  nextTablePage(ev) {
    if (this.numberPages.includes(this.pageNumber + 1)) {
      this.pageNumber += 1
      this.doDataTable()
    }
  }

  doFooterTable(pagination) {
    var footerHtml = `<div class="row">
                        <div class="col-2 d-flex">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span data-app--helpers--search-target="footerTable"></span>
                          </div>
                        </div>
                        <div class="col-10 px-0">
                          <div class="card-actions align-items-center justify-content-end py-0 f-065">
                            <span class="align-self-center mb-1 mx-1 text-muted">Itens por Página:</span>
                            <div class="dropdown">
                              <button aria-expanded="false" aria-haspopup="true" class="btn btn-outline dropdown-toggle f-065" data-toggle="dropdown" type="button">${this.controllerNumber.numberMask(pagination.itemsOnPage)}</button>
                              <div class="dropdown-menu dropdown-menu-right menu">
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="300">300</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="750">750</span>
                                <span class="dropdown-item pointer" data-action="click->${this.controllerName}#itemsLength" data-items="1000">1.000</span>
                              </div>
                            </div>
                            <span class="align-self-center mb-1 mr-2 text-muted">${this.controllerNumber.numberMask(pagination.fromItem)}-${this.controllerNumber.numberMask(pagination.toItem)} de ${this.controllerNumber.numberMask(pagination.totalItems)}</span>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#previousTablePage"><i class="material-icons md-sm">chevron_left</i></a>
                            <a class="btn btn-outline py-0" data-action="click->${this.controllerName}#nextTablePage"><i class="material-icons md-sm">chevron_right</i></a>
                          </div>
                        </div>
                      </div>`

    this.footerTableTarget.innerHTML = footerHtml
  }

  goToShowPage(ev) {
    var token = ev.target.closest(".itemRow").dataset.token
    window.open(`/a/contratacoes/${token}`, `_blank`)
  }

  createOnboard(ev) {
    this.purchase_id = ev.currentTarget.closest(".itemRow").dataset.purchaseId

    var r = confirm(`Tem certeza que deseja criar o Onboard para a Contratação?`)
    if (r) {
      this.requestCreateOnboard()
    }
  }

  requestCreateOnboard() {
    var url = "/operations/onboards/entities/create_from_purchase"
    var method = "POST"
    this.send_data = { current_user: {}, purchase: {} }

    this.send_data.purchase.id = this.purchase_id
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `operation_onboard_entities`


    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var onboard = response.data.cln

          controller.application.onboards[controller.application.onboards.length] = onboard

          controller.application.purchase_report.forEach(element => {
            if (element.purchase_id == controller.purchase_id) {
              element.has_onboard = true
              element.onboard_id = onboard.id
              element.onboard_journey_status = onboard.journey_status
              element.onboard_journey_status_pretty = onboard.journey_status_pretty
              element.onboard_started_at = onboard.started_at
              element.onboard_started_at_pretty = onboard.started_at_pretty

              controller.purchase = element
            }
          });

          controller.doIndexListHtml()
          controller.sendOnboardEmailNotification()
        }
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  sendOnboardEmailNotification() {
    var purchase = this.application.purchase
    this.send_data = { current_user: {}, notification: {} }

    var partyName = ``
    this.purchase.parties.forEach(name => {
      // partyName += `<h6 class="mb-0 f-065">${party.party_name}</h6>`
      partyName += `| ${name} `
    })

    this.send_data.current_user.current_user_id = this.application.current_user.id

    this.send_data.notification.onboard_id = this.purchase.onboard_id
    this.send_data.notification.onboard_token = this.purchase.purchase_token
    this.send_data.notification.onboard_name = `${this.purchase.product_service_pretty} ${this.purchase.product_accrual_name_pretty} ${this.purchase.product_kind_pretty}`
    this.send_data.notification.onboard_parties = partyName.trim()
    this.send_data.notification.sender_id = this.application.current_user.account_id
    this.send_data.notification.sender_name = `${this.getControllerByIdentifier("app--visits--users").getFullNameTratment(this.application.current_user)}`
    this.send_data.notification.action = `criou um novo Onboard: `
    this.send_data.notification.body = `${this.purchase.product_service_pretty} - ${this.purchase.product_accrual_name_pretty} - ${this.purchase.product_kind_pretty}`

    var url = "/operations/onboards/entities/send_email_notification"
    var method = "POST"

    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  refreshPurchases() {
    this.getPurchaseReport()
  }

  getPurchaseReport() {
    const data = { onboard: { token: this.application.onboard_token }, current_user: { current_user_id: this.application.current_user.id } }
    const url = "/operations/onboards/reports/purchase_report"
    const init = { method: "POST", credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    var controller = this
    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        controller.application.purchase_report = response.data.cln
         controller.doIndexListHtml()
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  checkProductServiceOnboards(element) {
    var productServices = ["cancellation", "additive", "renewal"]

    if (productServices.includes(element.product_service)) {
      return false
    } else {
      return true
    }
  }

  userCanUpdate(ticketId) {
    var ticket = {}
    this.application.in_process_tickets.forEach(element => {
      if (element.id == ticketId) {
        ticket = element
      }
    })
    var allowed = [ticket.owner_id, ticket.performer_id]
    if (allowed.includes(this.application.current_user.account_id) && this.stage != "done") {
      return true
    } else {
      return false
    }
  }

  userCanUpdate() {
    var allowed = [this.application.opportunity.prospector_id, this.application.opportunity.closer_id]

    const hasSalesTeam = (currentValue) => currentValue != undefined

    if (!allowed.every(hasSalesTeam) || (allowed.includes(this.application.current_user.account_id) && this.application.opportunity.stage != "gain" && this.application.opportunity.stage != "lost")) {
      return true
    } else {
      return false
    }
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0
      controller.validGroupTargets.forEach(element => {
        if (this.fromStringToBoolean(element.dataset.valid) == false) {
          len += 1
        }
      });
      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  goToURL(ev) {
    var url = ev.currentTarget.dataset.url
    window.open(url, `_self`)
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  mapElements(array, field) {
    var results = []
    array.forEach(element => {
      results[results.length] = element[field]
    });
    this.getControllerByIdentifier("app--helpers--console").console(results)
    return results
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)

    return number
  }

  requireUser() {
    var user = this.getControllerByIdentifier("app--visits--users").checkUser()

    if (user) {
      this.doMainGrid()
    } else {
      window.open(window.location.origin, `_self`)
    }
  }

  sortTable(ev) {
    var mode = ev.currentTarget.dataset.mode
    var field = ev.currentTarget.dataset.field

    if (mode == `desc`) {
      ev.currentTarget.nextElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    } else if (mode == `asc`) {
      ev.currentTarget.previousElementSibling.classList.remove("d-none")
      ev.currentTarget.classList.add("d-none")
    }

    this.sort.mode = mode
    this.sort.field = field
    this.doDataTable()
  }

  // controller.bodyTableTarget.insertAdjacentHTML("beforeend", controller.getControllerByIdentifier("app--helpers--table").doTablePreloader(9, 6))
  // var controller = this
  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
