import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["formCard", "formTitle", "formCardBody", "medNameInput", "dateInput", "chartNameInput", "groupInput",
                    "previousBalanceInput", "balanceInput", "cardInput", "cardList", "cardDiv", "saveBtn"]


  connect() {
    this.controllerName = `financials--books--statements--balances--save`
  }

  cancelSave() {
    this.getControllerByIdentifier("financials--books--statements--balances--index").doIndexListHtml()
  }

  saveBalance() {
    this.send_data = { current_user: {}, balance: {} }
    this.saveBtnTarget.disabled = true

    if (this.actionMode == `edit`) {
      this.send_data.balance.id = this.current_balance.id
    }
    this.send_data.balance.previous_balance = this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.previousBalanceInputTarget.value)
    
    this.send_data.current_user.current_user_id = this.application.current_user.id
    this.send_data.current_user.feature = `financial_statement_balances`

    this.requestSave()
  }

  requestSave() {
    var url = "/financials/books/statements/balances/update_previous_balance"
    var method = "PUT"
    const init = { method: method, credentials: "same-origin", headers: { "X-CSRF-Token": this.application.token, 'Content-Type': 'application/json' }, body: JSON.stringify(this.send_data) }
    var controller = this

    fetch(url, init)
      .then(response => response.json())
      .then(response => {
        if (response.save) {
          var balances = response.data.cln
          balances.forEach(balance => {
            controller.application.balances.forEach((element, i) => {
              if (element.id == balance.id) {
                controller.application.balances.splice(controller.application.balances.indexOf(element), 1, balance)
              }
            })
          })
          
          controller.cancelSave()
        } else {
          controller.saveBtnTarget.disabled = false
        }
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar(response.type, response.message, 2000)
      })
      .catch(error => {
        controller.getControllerByIdentifier("app--helpers--console").console(error)
        controller.getControllerByIdentifier("app--helpers--snackbar").doSnackbar("danger", controller.getControllerByIdentifier("app--helpers--messages").generalError(), 3000)
      })
  }

  doFormHtml() {
    this.stopRefreshing()

    if (this.application.permissions.financial_statement_balances.can_update) {
      var saveBtnHtml = `<button type="button" class="btn btn-primary btn-sm py-2 btn-table f-065" data-action="click->${this.controllerName}#saveBalance" data-${this.controllerName}-target="saveBtn">Salvar</button>`
    } else {
      var saveBtnHtml = ``
    }

    var html = `<div class="row">
                  <div class="col-4 offset-4">
                    <div class="card" style="width:100%;display:relative;" data-${this.controllerName}-target="formCard" data-action="resize@window->${this.controllerName}#layout">
                      <div class="card-header d-flex align-items-center card-header-table-list f-065">
                        <h6 class="card-title display-4 py-3" style="font-size:110%;margin-bottom:0px;" data-${this.controllerName}-target="formTitle"></h6>
                      </div>
                      <div class="card-body py-0 px-2" style="overflow:auto;" data-${this.controllerName}-target="formCardBody">
                        <div class="row mt-3">
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="medNameForm">Empresa</label>
                                <input aria-describedby="medNameFormHelp" class="form-control" id="medNameForm" data-${this.controllerName}-target="medNameInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="dateForm">Competência</label>
                                <input aria-describedby="dateFormHelp" class="form-control" id="dateForm" data-${this.controllerName}-target="dateInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="chartNameForm">Conta</label>
                                <input aria-describedby="chartNameFormHelp" class="form-control" id="chartNameForm" data-${this.controllerName}-target="chartNameInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="groupForm">Grupo Conta</label>
                                <input aria-describedby="groupFormHelp" class="form-control" id="groupForm" data-${this.controllerName}-target="groupInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="previousBalanceInputForm">Saldo Inicial</label>
                                <input aria-describedby="previousBalanceInputFormHelp" class="form-control" id="previousBalanceInputForm" data-${this.controllerName}-target="previousBalanceInput" data-action="blur->${this.controllerName}#currencyMask keypress->${this.controllerName}#currencyMask keyup->${this.controllerName}#currencyMask blur->${this.controllerName}#bindBalance keyup->${this.controllerName}#bindBalance" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <div class="floating-label floating-label-sm">
                                <label for="balanceInputForm">Saldo Final</label>
                                <input aria-describedby="balanceInputFormHelp" class="form-control" id="balanceInputForm" data-${this.controllerName}-target="balanceInput" placeholder="" type="text" required>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="card-footer border-top text-right py-1">
                        <button type="button" class="btn btn-secondary btn-sm py-2 btn-table f-065" data-dismiss="modal" data-action="click->${this.controllerName}#cancelSave">Fechar</button>
                        ${saveBtnHtml}
                      </div>
                    </div>
                  </div>
                </div>`

    var controller = this
    new Promise(function (resolve) {
      resolve(controller.getControllerByIdentifier("financials--books--statements--balances--dashboard").contentTarget.innerHTML = html)
    }).then(() => {
      controller.formCardTarget.style.height = ($(window).height() * 0.5) + "px"
      if (controller.actionMode == `edit`) {
        controller.formTitleTarget.innerText = `Editando Conta de Balanço`

        controller.medNameInputTarget.value = controller.current_balance.med_name
        controller.dateInputTarget.value = controller.current_balance.date_pretty
        controller.chartNameInputTarget.value = controller.current_balance.chart_name
        controller.groupInputTarget.value = controller.current_balance.group_pretty
        controller.previousBalanceInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_balance.previous_balance * 100))
        controller.balanceInputTarget.value = controller.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(controller.current_balance.balance * 100))

        controller.medNameInputTarget.disabled = true
        controller.dateInputTarget.disabled = true
        controller.chartNameInputTarget.disabled = true
        controller.groupInputTarget.disabled = true
        controller.balanceInputTarget.disabled = true

      } else if (controller.actionMode == `new`) {
        controller.formTitleTarget.innerText = `Nova Conta de Balanço`
      }

      // controller.refreshSaveBtn()
      controller.getControllerByIdentifier("app--helpers--forms").floatingLabel()
    })
  }

  bindBalance(ev) {
    var previousBalance = Number(this.getControllerByIdentifier("app--helpers--numbers").fromCurrencyToNumber(this.previousBalanceInputTarget.value))
    var balance = Number(this.current_balance.balance)
    var newBalance = previousBalance + balance

    this.balanceInputTarget.value = this.getControllerByIdentifier("app--helpers--numbers").currencyMask(parseInt(newBalance * 100))
  }

  currencyMask(ev) {
    this.getControllerByIdentifier("app--helpers--numbers").changeNumberToCurrency(ev)
  }

  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === identifier;
    });
  }

  nameTarget(target) {
    return this.targets.find(target)
  }

  layout() {
    var targets = ["mainCard"]
    var identifier = ""
    // this.getControllerByIdentifier("app--helpers--layout").resizeMainCard(targets, identifier)
  }

  refreshSaveBtn() {
    var controller = this
    this.refreshTimer = setInterval(function () {
      var len = 0

      if (controller.nameInputTarget.value == ``) {
        len += 1
      }

      if (controller.kindInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.medInputTarget.innerText == ``) {
        len += 1
      }

      if (controller.chartInputTarget.value == ``) {
        len += 1
      }

      if (len == 0) {
        controller.saveBtnTarget.disabled = false
      } else {
        controller.saveBtnTarget.disabled = true
      }
    }, 200);
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  randonNumber() {
    var number = Math.floor(Math.random() * 1000)
    return number
  }

  // new Promise(function (resolve) {
  //   resolve()
  // }).then(() => {
  // }) 

}
